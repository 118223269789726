import FUNDynamixLogo from 'assets/images/FUNDynamixLogoWithoutBG.png';
import { downloadXLSX, setDisclaimer } from 'components/excelExport/export';
import { Row, Workbook, Worksheet } from 'exceljs';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { ExportingMimeTypeValue } from 'highcharts/highstock';
import { addFontsToSvg, asOfDateFormat, fontFamilies, getMomentObjectFrom, todayDateString } from 'utils';
import { ValueTypes } from 'utils/valuesFormatter';
import logoFile from '../../../src/assets/images/FUNDynamixLogo.png';

export enum Orientations {
    vertical = 'vertical',
    horizontal = 'horizontal',
}

export type CardExport = {
    exportFile: string;
    card?: {
        title: string;
        etf?: string;
        ticker?: string;
        asOfDate: string;
    };
    charts: Array<{
        href?: React.RefObject<HighchartsReact.RefObject | null>;
        worksheetName: string;
        columns: Map<string, ValueTypes>;
    }>;
};

export type MultipleExport = {
    fileName: string;
    chartsProps: Array<{
        chartRef?: React.RefObject<HighchartsReact.RefObject>;
        worksheetName: string;
        rows?: Array<Array<string | number>>;
        columnsToFormatting: Map<string, ValueTypes>;
    }>;
    exportHeaders?: {
        title: string;
        asOfDate: string;
        columnHeaders: Array<string>;
    };
};

type ExportMultipleChartsProps = {
    multipleExport: MultipleExport;
    type: string;
};

type ExportChartProps = {
    chartRef: React.RefObject<HighchartsReact.RefObject | null>;
    type: string;
    headers?: {
        title?: string;
        asOfDate?: string;
        ticker?: string;
        etfName?: string;
        columns?: Map<string, ValueTypes>;
    };
};

type WorksheetHeaders = {
    logoID: number;
    headers?: {
        title?: string;
        asOfDate?: string;
        columnHeaders: Array<string>;
        ticker?: string;
        etfName?: string;
    };
    rows?: Array<Array<string | number | Date>>;
};

function getSVG(
    charts: Array<HighchartsReact.RefObject>,
    options: Highcharts.Options,
    callback: (svg: string) => void,
) {
    const svgArr: Array<string> = [];
    let top: number = 0;
    let width: number = 0;

    function addSVG(svgres: string, orientation: Orientations = Orientations.horizontal) {
        // Grab width/height from exported chart
        const svgWidthRExp: RegExp = /^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/;
        const svgresWidthMatch: RegExpMatchArray | null = svgres.match(svgWidthRExp);
        if (svgresWidthMatch === null) return;
        const svgWidth: number = +svgresWidthMatch[1];

        const svgHeightRExp: RegExp = /^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/;
        const svgresHeightMatch: RegExpMatchArray | null = svgres.match(svgHeightRExp);
        if (svgresHeightMatch === null) return;
        let svgHeight: number = +svgresHeightMatch[1];

        let svg = '';

        if (orientation === Orientations.vertical) {
            // Offset the position of this chart in the final SVG
            svg = svgres.replace('<svg', `<g transform="translate(0,${top})" `);
            svg = svg.replace('</svg>', '</g>');
            top += svgHeight;
            width = Math.max(width, svgWidth);
        } else if (orientation === Orientations.horizontal) {
            // for horizontal orientation
            svg = svgres.replace('<svg', `<g transform="translate(${width}, 0)" `);
            svg = svg.replace('</svg>', '</g>');
            width += svgWidth;
            top = Math.max(top, svgHeight);
        }

        svgArr.push(svg);
    }

    function exportChart(i: number) {
        if (i === charts.length) {
            return callback(
                '<svg height="' +
                top +
                '" width="' +
                width +
                '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
                svgArr.join('') +
                '</svg>',
            );
        }

        addSVG(charts[i].chart.getSVG(options));
        exportChart(i + 1);
    }
    exportChart(0);
}

function exportChartsAsImage(charts: Array<HighchartsReact.RefObject>, options: Highcharts.Options) {
    options = Highcharts.merge(Highcharts.getOptions().exporting, options);

    // Get SVG asynchronously and then download the resulting SVG
    getSVG(charts, options, function (svg) {
        Highcharts.downloadSVGLocal(svg, options.exporting ? options.exporting : {}, function () {
            console.log('Failed to export on client side');
        });
    });
}

export async function exportChartAsImgWithFonts(chart: Highcharts.Chart, chartOptions: Highcharts.Options, fonts: string[] = []): Promise<void> {
    const defaultFonts = [
        '/fonts/GraphikRegular.woff2',
        '/fonts/GraphikRegular.woff',
        '/fonts/GraphikMedium.woff2',
        '/fonts/GraphikMedium.woff'
    ]

    let chartSvgString = chart.getSVG(chartOptions);

    chartSvgString = await addFontsToSvg(chartSvgString, fonts.length > 0 ? fonts : defaultFonts);

    Highcharts.downloadSVGLocal(chartSvgString, chart.options.exporting ? chart.options.exporting : {}, function () {
        console.log('Failed to export on client side');
    });
}

export function getExportDefaultChartOptions(params: { title?: string, subtitle?: string, ticker?: string }): Highcharts.Options {
    const { title, subtitle, ticker } = params;
    let defaultChartOptions: Highcharts.Options = { chart: { style: { fontFamily: fontFamilies.GraphikRegular } } };

    if (title) {
        let chartTitle = title;
        if (ticker) {
            chartTitle += `<span style="font-family:${fontFamilies.GraphikRegular};"> - ${ticker}</span>`
        }
        const titleOptions: Highcharts.TitleOptions = {
            useHTML: true,
            text: chartTitle,
            align: 'left',
            style: {
                fontSize: '18px',
                color: '#002B5A',
                fontFamily: fontFamilies.GraphikMedium
            },
            x: -15
        }
        defaultChartOptions = Highcharts.merge(defaultChartOptions, { title: titleOptions });
    }

    if (subtitle) {
        const subTitleOptions: Highcharts.SubtitleOptions = {
            text: subtitle,
            align: 'left',
            style: {
                fontSize: '14px',
                color: '#57626a',
            },
            x: -15,
        }
        defaultChartOptions = Highcharts.merge(defaultChartOptions, { subtitle: subTitleOptions });
    }
    return defaultChartOptions;
}

export function addLogoToHighchart(chart: Highcharts.Chart) {
    chart.renderer
        .image(FUNDynamixLogo, 25, chart.chartHeight - 35, 100, 25)
        .add();
}

export function addAsOfDateToHighchart(chart: Highcharts.Chart, asOfDate: string) {
    if (asOfDate) {
        const formattedAsOfDate = getMomentObjectFrom(asOfDate).format('MM/DD/YYYY');
        chart.renderer
            .text(`Data as of ${formattedAsOfDate}`, chart.chartWidth - 25, chart.chartHeight - 15)
            .attr({ align: 'right', zIndex: 9999 })
            .css({ color: 'rgb(118, 129, 140)', fontSize: '11px', fontFamily: fontFamilies.GraphikRegular })
            .add();
    }
}

export function getTitleWithTicker(title: string, ticker: string) {
    return `${title}<span style="font-family:${fontFamilies.GraphikRegular};"> - ${ticker}</span>`;
}

function parseString(input: string): Array<Array<string>> {
    return input?.split('\n').map((element) => element.split(',').map((element) => element.replaceAll('"', '')));
}

const formatValue = (value: any, type: ValueTypes | undefined) => {
    if (type === ValueTypes.Numeral || type === ValueTypes.Integer || type === ValueTypes.Percentage || type === ValueTypes.Currency)
        return Number(value);
    if (type === ValueTypes.Date) return asOfDateFormat(value, 'MM/DD/YYYY');
    return value;
};

const typifyValues = (
    table: Array<Array<string>>,
    columnsToFormatting: Map<String, ValueTypes>,
): Array<Array<string | number>> => {
    table[0].forEach((columnName, columnIndex) => {
        if (columnsToFormatting.has(String(columnName))) {
            table?.forEach((item, itemIndex) => {
                // skip header
                if (itemIndex === 0) return;
                // change value format
                if (item[columnIndex])
                    item[columnIndex] = formatValue(item[columnIndex], columnsToFormatting.get(String(columnName)));
            });
        }
    });
    return table;
};

const exportChartsAsImageOfType = (
    charts: Array<React.RefObject<HighchartsReact.RefObject> | undefined>,
    type: ExportingMimeTypeValue,
    fileName: string,
): void => {
    const filteredCharts = charts
        .map((element) => element?.current)
        .filter((element) => element !== undefined) as Array<HighchartsReact.RefObject>;
    exportChartsAsImage(filteredCharts, {
        exporting: {
            type: type,
            filename: fileName,
        },
    });
};

function configureExcelWorkSheet(wb: Workbook) {
    const addRow = (data: Array<string | Date | undefined>): Row => {
        return ws.addRow(data);
    };

    let ws: Worksheet;
    let logoID: number;

    const addData = (exportData: Array<Array<string | number | Date>>): Row[] => ws.addRows(exportData);

    const setHeaderBorders = (workSheetRows: Row[]) => {
        workSheetRows[0].eachCell((cell, ColNumber) => {
            cell.style = { font: { bold: true } };
            cell.border = { top: { style: 'thin' }, bottom: { style: 'thin' } };
        });
        return workSheetRows;
    };

    const setColumnWidths = (workSheetRows: Row[]) => {
        for (let i = 1; i <= workSheetRows[0].actualCellCount; i++) {
            ws.columns[i - 1].width = Math.max(
                ...workSheetRows.map((row) => row.getCell(i).text.length + 1),
                12,
                ...(i === 1 ? ['Download Date:'.length, 'As-Of Date:'.length] : [0]),
            );

        }
        return workSheetRows;
    };

    type Format = {
        numberFormat: string;
        description?: string;
        isRightAlign?: boolean
    };

    const availableColumnFormats = new Map<ValueTypes, Format>([
        [ValueTypes.Numeral, { numberFormat: '##0.00', isRightAlign: true }],
        [ValueTypes.Percentage, { numberFormat: '##0.00', description: '%', isRightAlign: true }],
        [ValueTypes.Integer, { numberFormat: '##0', isRightAlign: true }],
        [ValueTypes.Currency, { numberFormat: '##0.00', description: '$', isRightAlign: true }],
        [ValueTypes.Date, { numberFormat: '', isRightAlign: true }],
        [ValueTypes.Text, { numberFormat: '' }],
    ]);

    const setColumnFormats = function (dataColumns: Map<string, ValueTypes>) {
        return function (workSheetRows: Row[]) {
            let headers = workSheetRows[0].model?.cells?.map((cell) => cell.value?.toString() || '') || [];

            if (headers.length > 0) {
                workSheetRows
                    .filter((row, index) => index > 0)
                    .map((row, index) => {
                        row.eachCell((cell, colNumber) => {
                            let key = headers[colNumber - 1];
                            if (
                                dataColumns.has(key) &&
                                availableColumnFormats.has(dataColumns.get(key) ?? ValueTypes.Text)
                            ) {
                                const columnFormat = availableColumnFormats.get(
                                    dataColumns.get(key) ?? ValueTypes.Text,
                                );

                                if (columnFormat?.numberFormat) cell.numFmt = columnFormat.numberFormat;
                                if (dataColumns.get(key) === ValueTypes.Date) cell.alignment = { horizontal: 'right' };

                                if (index === 0) {
                                    let headerCell = workSheetRows[0].getCell(colNumber);

                                    if (columnFormat?.description)
                                        headerCell.value = headerCell.value + ` (${columnFormat?.description})`;

                                    if (columnFormat?.isRightAlign) headerCell.alignment = { horizontal: 'right' };
                                }
                            }
                        });
                        return '';
                    });
            }
            return workSheetRows;
        };
    };

    const exportChart = {
        addWorkSheet: (sheetName: string) => {
            ws = wb.addWorksheet(sheetName);
            ws.addImage(logoID, {
                tl: { col: 0, row: 0 },
                ext: { width: 130, height: 30 },
            });

            ws.mergeCells('A1', 'A2');
            return exportChart;
        },
        addImageToWorkbook: async (): Promise<void> => {
            logoID = await fetch(logoFile)
                .then((result: Response) => result.arrayBuffer())
                .then((buffer) => wb.addImage({ buffer: buffer, extension: 'png' }));
        },
        addTitle: (title: string = '') => {
            let row = addRow([title]);
            row.getCell(1).style = { font: { bold: true } };
            return exportChart;
        },
        addETF: (etf?: string) => {
            if (etf) {
                addRow(['ETF Name:', etf]);
            }
            return exportChart;
        },
        addTicker: (ticker?: string) => {
            if (ticker) {
                addRow(['Ticker:', ticker]);
            }
            return exportChart;
        },
        addDownDate: () => {
            addRow(['Download Date:', todayDateString('MM/DD/YYYY')]).getCell(2).alignment = { horizontal: 'right' };
            return exportChart;
        },
        addAsOfDate: (asOfDate: string = '') => {
            addRow(['As-Of Date:', asOfDateFormat(asOfDate, 'MM/DD/YYYY')]).getCell(2).alignment = {
                horizontal: 'right',
            };
            return exportChart;
        },
        addBlankRow: () => {
            addRow([]);
            return exportChart;
        },
        addExportData: (columns: Map<string, ValueTypes>, exportData?: Array<Array<string | number | Date>>) => {
            if (exportData && exportData.length > 0) {
                [addData, setHeaderBorders, setColumnFormats(columns), setColumnWidths].reduce(
                    (arg: any, fn) => fn(arg),
                    exportData,
                );
            }
            return exportChart;
        },
        formatDataRows: (columns: Map<string, ValueTypes>) => { },
    };
    return exportChart;
}

const excelExportForCard = async (cardExport: CardExport) => {
    let workbook: Workbook = new Workbook();

    let exportChart = configureExcelWorkSheet(workbook);
    await exportChart.addImageToWorkbook();

    let headers = cardExport.card;
    cardExport.charts.map((chart) => {
        let data = getChartData(chart.columns, chart.href);

        if (data && data.length > 0) {
            exportChart
                .addWorkSheet(chart.worksheetName)
                .addTitle(headers?.title)
                .addETF(headers?.etf)
                .addTicker(headers?.ticker)
                .addDownDate()
                .addAsOfDate(headers?.asOfDate)
                .addBlankRow()
                .addExportData(chart.columns, data);
        }
        return '';
    });
    if (workbook.worksheets.length > 0) downloadXLSX(setDisclaimer(workbook), cardExport.exportFile);
};

function getChartData(
    columns: Map<string, ValueTypes>,
    chartRef?: React.RefObject<HighchartsReact.RefObject | null>
): Array<Array<string | number>> | undefined {
    const csvString = chartRef?.current?.chart.getCSV();

    if (!csvString) return;
    let data = parseString(csvString);
    if (!data || data.length <= 1) return;

    if (exportHasDuplicateColumns(data[0])) {
        data = mergeDuplicateColumns(data);
    }
    // typify values
    return typifyValues(data as Array<Array<string>>, columns);
}

async function getLogoID(workbook: any): Promise<number> {
    const buffer = await (await fetch(logoFile)).arrayBuffer();
    return workbook.addImage({ buffer: buffer, extension: 'png' });
}

function configureWorkSheet(sheetHeaders: WorksheetHeaders) {
    const cellBoldStyle = { font: { bold: true } };
    const cellAlignRight = { horizontal: 'right' };
    const cellBorderStyle = { style: 'thin' };

    return function (worksheet: any) {
        let addFormattedDate = (title: string, date?: string, format?: string) => {
            if (date) {
                if (format) {
                    date = getMomentObjectFrom(date).format(format);
                }
                worksheet.addRow([title, date]).getCell(2).alignment = cellAlignRight;
            }
        };

        //add logo
        worksheet.addImage(sheetHeaders.logoID, {
            tl: { col: 0, row: 0 },
            ext: { width: 130, height: 30 },
        });
        worksheet.mergeCells('A1', 'A2');

        //add title
        if (sheetHeaders.headers?.title) {
            worksheet.addRow([sheetHeaders.headers.title]).getCell(1).style = { ...cellBoldStyle };
            worksheet.mergeCells('A3:F3');
        }

        if (sheetHeaders.headers?.etfName && sheetHeaders.headers?.etfName !== '') {
            worksheet.addRow(['ETF Name:', sheetHeaders.headers?.etfName]);
        }

        if (sheetHeaders.headers?.ticker && sheetHeaders.headers?.ticker !== '') {
            worksheet.addRow(['Ticker:', sheetHeaders.headers?.ticker]);
        }

        //add download date
        addFormattedDate('Download Date:', todayDateString('MM/DD/YYYY'));

        //add asOf date
        addFormattedDate('As-Of Date:', sheetHeaders.headers?.asOfDate, 'MM/DD/YYYY');
        worksheet.addRow([]);

        //add column headers and border style
        let headerRow = worksheet.addRow(sheetHeaders.headers?.columnHeaders);
        let indexes: Array<number> =
            sheetHeaders.headers?.columnHeaders.map((columnHeader, index) => {
                let cell = headerRow.getCell(index + 1);

                cell.style = { ...cellBoldStyle };
                cell.border = { top: cellBorderStyle, bottom: cellBorderStyle };
                return index;
            }) || [];

        //set column widths and alignments
        if (sheetHeaders.rows !== undefined && sheetHeaders.headers?.columnHeaders) {
            let data = sheetHeaders.rows;
            let columnHeaders = sheetHeaders.headers?.columnHeaders;

            indexes.map((index) => {
                let cell = headerRow.getCell(index + 1);
                let columnWidth = 0;

                if (data.every((row) => typeof row[index] === 'number')) {
                    cell.alignment = cellAlignRight;
                } else {
                    columnWidth = Math.max(...data.map((dataRow) => dataRow[index].toString().length));
                }

                worksheet.columns[index].width =
                    Math.max(
                        ...[
                            columnHeaders[index].length,
                            columnWidth,
                            12, //default min. width of column
                            ...(index === 0 ? ['Download Date:'.length, 'As-Of Date:'.length] : [0]),
                        ],
                    ) + 1;

                return '';
            });
        }
    };
}

export const exportMultipleCharts = ({ multipleExport, type }: ExportMultipleChartsProps): void => {
    switch (type) {
        case 'xlsx':
            let cardExport: CardExport = {
                exportFile: multipleExport.fileName,
                card: multipleExport.exportHeaders,
                charts: multipleExport.chartsProps.map((prop) => {
                    return {
                        href: prop.chartRef,
                        worksheetName: prop.worksheetName,
                        columns: prop.columnsToFormatting,
                    };
                }),
            };
            excelExportForCard(cardExport);
            break;
        case 'jpeg':
        case 'png':
        case 'svg+xml':
            exportChartsAsImageOfType(
                multipleExport.chartsProps.map((element) => element.chartRef),
                `image/${type}`,
                multipleExport.fileName,
            );
            break;
        case 'pdf':
            exportChartsAsImageOfType(
                multipleExport.chartsProps.map((element) => element.chartRef),
                `application/${type}`,
                multipleExport.fileName,
            );
            break;
    }
};

export const exportChart = ({ chartRef, type, headers }: ExportChartProps): void => {
    switch (type) {
        case 'xlsx':
            if (headers?.columns) {
                let cardExport: CardExport = {
                    exportFile: chartRef?.current?.chart?.getFilename() || '',
                    card: {
                        title: headers.title || '',
                        etf: headers.etfName,
                        ticker: headers.ticker,
                        asOfDate: headers.asOfDate || '',
                    },
                    charts: [
                        {
                            href: chartRef,
                            worksheetName: chartRef?.current?.chart?.getFilename() || '',
                            columns: headers.columns,
                        },
                    ],
                };
                excelExportForCard(cardExport);
            } else {
                import('exceljs').then(async (ExcelJS) => {
                    const workbook = new ExcelJS.Workbook();

                    const logoID = await getLogoID(workbook);
                    const csvString = chartRef?.current?.chart?.getCSV();

                    if (!csvString) return;

                    const fileName = chartRef?.current?.chart?.getFilename();
                    let dataRow = parseString(csvString);

                    if (exportHasDuplicateColumns(dataRow[0])) {
                        dataRow = mergeDuplicateColumns(dataRow);
                    }

                    const worksheet = workbook.addWorksheet(fileName);
                    let rows: Array<Array<string | number | Date>> = dataRow;
                    let headerRow = dataRow.shift() || [];

                    if (headers?.columns) {
                        headerRow = Array.from(headers.columns.keys()) || [];
                        let columnValueTypes = Array.from(headers.columns.values()) || [];

                        rows = dataRow.map((row) => {
                            return row.map((item, index) => {
                                switch (columnValueTypes[index]) {
                                    case ValueTypes.Date:
                                        return getMomentObjectFrom(item).format('MM/DD/YYYY');
                                    case ValueTypes.Numeral:
                                        return Number(item);
                                    default:
                                        return item;
                                }
                            });
                        });
                    }

                    (function (setWorksheetHeadersFn, worksheet) {
                        setWorksheetHeadersFn(worksheet);
                    })(
                        configureWorkSheet({
                            logoID,
                            headers: {
                                ...headers,
                                columnHeaders: headerRow,
                            },
                            rows: rows,
                        }),
                        worksheet,
                    );

                    worksheet.addRows(rows);
                    downloadXLSX(setDisclaimer(workbook), `${fileName}`);
                });
            }
            break;
        case 'jpeg':
            chartRef?.current?.chart.exportChart({ type: `image/${type}` }, {});
            break;
    }
};

function mergeDuplicateColumns(dataRows: Array<Array<string>>): Array<Array<string>> {
    let headers = dataRows.shift() || [];
    let newArray: Array<Array<string>> = [Array.from(new Set(headers))];

    for (let i = 0; i < dataRows.length; i++) {
        let mergeArray: Array<string> = [];

        for (let j = 0; j < dataRows[i].length; j++) {
            if (j < headers.length && headers[j] === headers[j + 1]) {
                mergeArray.push(dataRows[i][j] === '' ? dataRows[i][j + 1] : dataRows[i][j]);
            } else if (headers[j] !== headers[j - 1]) {
                mergeArray.push(dataRows[i][j]);
            }
        }
        newArray.push(mergeArray);
    }
    return newArray;
}

function exportHasDuplicateColumns(headers: Array<string>): boolean {
    return new Set(headers).size !== headers.length;
}
