import { Link } from '@mui/material';
import { Page } from 'analytics/Page';
import { ETFDetailsButton } from 'components/ETFButton';
import { invert } from 'lodash';
import { Route, Link as RouterLink, Routes } from 'react-router-dom';
import { MarketTrends } from '../components/MarketTrends';
import { MarketTrendsDetails } from '../components/MarketTrendsDetails';
import { MarketTrendsHome } from '../components/MarketTrendsHome';
import { MarketTrend } from '../types/market';

export const pathMapping: Record<string, string> = {
    [MarketTrend.FlowsToAssetsDetails]: 'flows-to-assets-details',
    [MarketTrend.PerformanceDetails]: 'performance-details',
    [MarketTrend.KeyAssetClassesDetails]: 'key-asset-classes-performance-details',
    [MarketTrend.SectorPerformanceDetails]: 'sector-performance-details',
    Home: '',
    'Market Trends': 'market-trends',
};

export const pathNamesMapping = invert(pathMapping);

type LinkProps = {
    pathName: MarketTrend;
    analyticsCallback?: () => void;
};

export function MarketTrendsDetailsLink({ pathName, analyticsCallback }: LinkProps) {
    return (
        <Link component={RouterLink} to={`/market-trends/${pathMapping[pathName]}`} onClick={analyticsCallback}>
            <ETFDetailsButton sx={{ paddingRight: '16px' }} />
        </Link>
    );
}

export function MarketTrendsRoutes() {
    return (
        <Routes>
            <Route element={<MarketTrends />}>
                <Route path='/' element={<MarketTrendsHome />} />
                {Object.entries(MarketTrend).map(([, marketTrend], i) => (
                    <Route
                        path={pathMapping[marketTrend]}
                        key={i}
                        element={
                            <Page name={marketTrend} group='market trends details'>
                                <MarketTrendsDetails label={marketTrend} />
                            </Page>
                        }
                    />
                ))}
            </Route>
        </Routes>
    );
}
