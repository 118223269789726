import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from 'components/themes/theme';
import { ENVIRONMENT_NAME } from 'config';
import * as React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from 'routes/scrollToTop';
import { Environments, ScrollingContextProvider } from 'utils';
import ErrorBoundary from '../components/fallbacks/ErrorBoundary';
import ErrorFallback from '../components/fallbacks/ErrorFallback';
import { AuthProvider } from '../lib/authentication';
import { queryClient } from '../lib/react-query-client';
import AppRoutes from '../routes';

type AppProviderProps = {
    children: React.ReactNode;
};

export function AppProvider({ children }: AppProviderProps) {
    return (
        // Ensures We Error Gracefully
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {/* React Query Client Provider */}
            <QueryClientProvider client={queryClient}>
                {/* Add React Query Dev tools in development */}
                {ENVIRONMENT_NAME === Environments.Local && <ReactQueryDevtools />}
                {/* MUI Theme Provider */}
                <ThemeProvider theme={appTheme}>
                    {/* Auth Provider */}
                    <AuthProvider>
                        {/* React Router */}
                        <Router>
                            <ScrollingContextProvider>
                                <ScrollToTop />
                                <AppRoutes />
                                {children}
                            </ScrollingContextProvider>
                        </Router>
                    </AuthProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}
