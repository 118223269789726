import { BarChart } from 'components/Chart';
import { addAsOfDateToHighchart, addLogoToHighchart, exportChartAsImgWithFonts, getExportDefaultChartOptions } from 'components/Chart/Export';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import { getMarginOptions, getSpacingOptions, getTooltipHTML } from 'components/Chart/Options';
import ChartFootnote from 'components/dataDisplay/ChartFootnote';
import { DataAsOfDate, ETFCard, ETFEmptyCard } from 'components/layout';
import { getEtfHoldingsAndExposure } from 'features/etfData/api/etfDetailsData';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import {
    CreditExposureDisplayRatings,
    CreditExposureRatings,
    CreditExposureRatingsToCreditExposureDisplayRatings,
    ValueTypes,
    isCloseOrEqualToZero
} from 'utils';
import { formatPercentages } from 'utils/valuesFormatter';
import { ETFDetailsParams, EtfDataCreditExposure, EtfDataCreditExposureSingleDisplayRating } from '../types/research';

export default function CreditExposure({
    cfraId,
    companyData,
    componentRendered,
}: {
    cfraId: string;
    companyData: ETFDetailsParams;
    componentRendered?: boolean;
}) {
    // getting UseQueryResult object with data for creditExposure chart
    const creditExposureDataQueryResult = getEtfHoldingsAndExposure<EtfDataCreditExposure>({
        dataType: 'credit-exposure',
        cfraId: cfraId,
    });
    // show card loading if data still loading
    if (creditExposureDataQueryResult.isLoading) {
        return <ETFCard isLoading={creditExposureDataQueryResult.isLoading} />;
    }
    const cardTitle = 'Credit Exposure';
    const subTitle = 'Current credit rating data for ETF holdings';
    const footnote = 'Credit rating information provided by S&P Global Ratings. Individual US sovereign securities not rated by S&P Global.';

    // return EmptyCard if no data
    if (
        !(
            creditExposureDataQueryResult.data &&
            creditExposureDataQueryResult.data.ratings &&
            creditExposureDataQueryResult.data.ratings.some((x) => Number.isFinite(x.agg_weighting))
        )
    )
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;
    // cut off UseQueryResult attributes, extract only creditExposure data
    const creditExposureData = creditExposureDataQueryResult.data.ratings;
    const creditExposureDataWithDisplayRatings: { [id: string]: EtfDataCreditExposureSingleDisplayRating } = {};
    // transform ratings from API to display ratings (cast AA+, BB+, etc. to AA, BB, etc.)
    creditExposureData.forEach(function (element) {
        // skip if unexpected rating
        if (!Object.values(CreditExposureRatings).includes(element.rating)) return;
        // skip if weighting is not a number
        if (typeof element.agg_weighting !== 'number') return;

        const display_rating = CreditExposureRatingsToCreditExposureDisplayRatings[element.rating];
        // add rating to the dictionary if it is not exist in it
        if (!creditExposureDataWithDisplayRatings.hasOwnProperty(display_rating)) {
            creditExposureDataWithDisplayRatings[display_rating] = {
                rating_id: element.rating_id,
                rating: display_rating,
                agg_weighting: element.agg_weighting,
                constituent_types: element.constituent_types,
            };
            return;
        }
        // plus weighting value if rating already in the dictionary
        creditExposureDataWithDisplayRatings[display_rating].agg_weighting += element.agg_weighting;
    });

    // create a list with ratings from an object for easy work with chart (sorting, use map function)
    let creditExposureDataWithDisplayRatingsList = Object.keys(creditExposureDataWithDisplayRatings).map((key) => {
        return creditExposureDataWithDisplayRatings[key];
    });

    // create credit ratings object as template based on which do sorting
    const orderedCreditRatings: Array<CreditExposureDisplayRatings> = [
        CreditExposureDisplayRatings.AAA,
        CreditExposureDisplayRatings.AA,
        CreditExposureDisplayRatings.A,
        CreditExposureDisplayRatings.BBB,
        CreditExposureDisplayRatings.BB,
        CreditExposureDisplayRatings.B,
        CreditExposureDisplayRatings.BelowB,
        CreditExposureDisplayRatings.NotRated,
        CreditExposureDisplayRatings.Other,
        CreditExposureDisplayRatings.Unclassified,
    ];

    // create list with credit ratings in display format which present in response from API
    const availableCreditRatings: Array<CreditExposureDisplayRatings> = creditExposureDataWithDisplayRatingsList.map(
        (element) => element.rating,
    );

    // find credit ratings which not present in response from API, but have to be in result chart with 0%
    // and add them to the list with 0%
    orderedCreditRatings.forEach((element) => {
        // add reting with 0 weighting if it missing in the response from API
        if (!availableCreditRatings.includes(element))
            creditExposureDataWithDisplayRatingsList.push({
                agg_weighting: 0,
                constituent_types: [],
                rating: element,
                rating_id: 0,
            });
    });

    // sort the output list with the exact order as in the orderedCreditRatings template
    creditExposureDataWithDisplayRatingsList.sort((a, b) => {
        return orderedCreditRatings.lastIndexOf(a.rating) - orderedCreditRatings.lastIndexOf(b.rating);
    });

    // create function for setting columns names in csv export
    const columnHeaderFormatter = function (item: any) {
        if (item instanceof Highcharts.Axis && item.isXAxis) {
            return 'Credit rating';
        } else return '% Held';
    };

    creditExposureDataWithDisplayRatingsList = creditExposureDataWithDisplayRatingsList.filter((value) => {
        // remove Other and Unclassified items with too small weighting
        if (
            value.rating === CreditExposureDisplayRatings.Other ||
            value.rating === CreditExposureDisplayRatings.Unclassified
        ) {
            return !isCloseOrEqualToZero(value.agg_weighting);
        }
        return true;
    });

    const categories: Array<string> = [];
    const categoriesData: Array<number> = [];

    // fill the list with the ratings values
    creditExposureDataWithDisplayRatingsList.forEach((val) => {
        categories.push(val.rating);
        categoriesData.push(val.agg_weighting);
    });

    function tooltipFormatter(this: any) {
        const getFormattedValue = (unformattedValue: number | null) =>
            unformattedValue !== null && unformattedValue !== 0 ? unformattedValue.toFixed(2) : unformattedValue;

        // display constituent_types for Other Rating
        const getOtherToolTipRow = () =>
            creditExposureDataWithDisplayRatingsList
                .filter((value) => value.rating === CreditExposureDisplayRatings.Other)[0]
                .constituent_types.map(
                    (value) =>
                        `<span>${value.type_name}: ${getFormattedValue(
                            formatPercentages(value.agg_weighting),
                        )}%</span>`,
                );

        const getToolTipRow = (): string => {
            return `${this.category === CreditExposureDisplayRatings.Unclassified ? 'Unclassified Rating: ' : ''}
            ${getFormattedValue(this.y as number)}%`;
        };

        return getTooltipHTML(
            this.category,
            this.category === CreditExposureDisplayRatings.Other ? getOtherToolTipRow() : getToolTipRow(),
        );
    }

    const asOfDate = creditExposureData
        .map((item) => item.as_of_date)
        .sort()
        .reverse()[0];

    const customExportsProps: CustomExportsProps = [
        {
            type: 'JPEG',
            callback: ({ chartRef }) => exportCreditExposureJpegChart({
                chartRef: chartRef.current,
                title: cardTitle,
                subTitle,
                ticker: companyData.ticker,
                footnote,
                asOfDate
            })
        }
    ]

    return (
        <ETFCard containerStyles={{ paddingBottom: '32px', position: 'relative' }}>
            <BarChart
                categories={categories}
                series={{ data: categoriesData }}
                columnHeaderFormatter={columnHeaderFormatter}
                title={cardTitle}
                subTitle={subTitle}
                exportFileName={`credit-exposure-chart-${companyData.ticker}-${companyData.exchange}`}
                tooltipFormatter={tooltipFormatter}
                useHTML={true}
                plotOptionsAnimations={componentRendered ? false : true}
                customExports={customExportsProps}
                exports={{
                    asOfDate: asOfDate,
                    etfName: companyData.composite_name,
                    columns: new Map<string, ValueTypes>([
                        ['Credit rating', ValueTypes.Text],
                        ['% Held', ValueTypes.Numeral],
                    ]),
                    ticker: companyData.ticker,
                }}
            />
            <DataAsOfDate date={asOfDate} />
            <ChartFootnote note={footnote} />
        </ETFCard>
    );
}


async function exportCreditExposureJpegChart(props: { chartRef: HighchartsReact.RefObject, title: string, subTitle: string, ticker: string, asOfDate: string, footnote: string }) {
    const { chartRef, title, subTitle, ticker, asOfDate, footnote } = props;
    const chart: Highcharts.Chart = chartRef.chart;

    const defaultChartOptions: Highcharts.Options = getExportDefaultChartOptions({ title, subtitle: subTitle, ticker });

    const chartOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            ...getSpacingOptions([25, 45, 50, 40]),
            ...getMarginOptions([110]),
            height: 600,
            width: 1000,
            events: {
                load: function (this: Highcharts.Chart) {
                    addLogoToHighchart(this);
                    addAsOfDateToHighchart(this, asOfDate);

                    if (footnote) {
                        this.renderer.text(footnote, 150, this.chartHeight - 15)
                            .attr({ align: 'left', zIndex: 99 })
                            .css({ color: '#999999', fontSize: '11px' })
                            .add();
                    }
                }
            }
        }
    }

    exportChartAsImgWithFonts(chart, chartOptions)
}