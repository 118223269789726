import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getDownloadAction } from 'analytics/utils';
import {
    ETFButtonsPannel,
    ETFButtonsPannelButtonProps,
    HorizontalPanel,
    ItemDescription,
    ItemHeader,
} from 'components/layout';
import HighchartsReact from 'highcharts-react-official';
import HC_rounded from 'highcharts-rounded-corners-fixed-negative';
import Highcharts from 'highcharts/highstock';
import Boost from 'highcharts/modules/boost';
import BoostCanvas from 'highcharts/modules/boost-canvas';
import HC_Data from 'highcharts/modules/export-data';
import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
import { useLayoutEffect, useRef } from 'react';
import { ValueTypes } from 'utils/valuesFormatter';
import './Chart.scss';
import ExportMenus, { CustomExportsProps } from './ExportMenus';
import { defaultOptions } from './Options';

HC_rounded(Highcharts);

const standartTickInterval = 5;

export function getTickIntervalFrom(categoriesData: Array<number | null>, tickCount: number = 10) {
    // if all values in categories data are not finite numbers, return 1, to not have error
    if (categoriesData.every((x) => !Number.isFinite(x))) return 1;

    const numbers: number[] = categoriesData.filter((value) => value !== null) as Array<number>;

    const maxNumber = Math.round(Math.max(...numbers)); // get max number for chart
    const minNumber = Math.floor(Math.min(...numbers)); // get min number for chart

    const span = Math.abs(maxNumber - minNumber);
    let tickInterval = Math.pow(10, Math.floor(Math.log(span / tickCount) / Math.LN10));
    const err = (tickCount / span) * tickInterval;

    if (err <= 0.15) tickInterval *= 10;
    else if (err <= 0.35) tickInterval *= 5;
    else if (err <= 0.75) tickInterval *= 2;

    return tickInterval === 0 ? standartTickInterval : tickInterval;
}

export function getMinAndMaxTick(
    tickInterval: number,
    resultSeriesData: Array<number | null>,
    useMinValue?: boolean,
): [number, number] {
    // if all values in resultSeriesData are not finite numbers, return [-1;1] or [0;1] to not have error
    if (resultSeriesData.every((x) => !Number.isFinite(x))) return [useMinValue ? -1 : 0, 1];

    if (Math.abs(tickInterval) === 0) tickInterval = standartTickInterval;
    // get min and max values based on customOptions.series
    let minTick =
        Math.floor(Math.min(...(resultSeriesData.filter((value) => value !== null) as Array<number>)) / tickInterval) *
        tickInterval;
    let maxTick =
        Math.ceil(Math.max(...(resultSeriesData.filter((value) => value !== null) as Array<number>)) / tickInterval) *
        tickInterval;

    if (Math.abs(maxTick - minTick) === 0) {
        maxTick = standartTickInterval;
        minTick = -standartTickInterval;
    }
    // if useMinValue is false, use zero as min value
    if (!useMinValue) minTick = 0;

    return [minTick, maxTick];
}

export const ChartTitle = styled(ItemHeader)(({ theme }) => ({
    paddingBottom: '12px',
}));

export const ChartDescription = styled(ItemDescription)(({ theme }) => ({
    lineHeight: 1,
    paddingBottom: '36px',
}));

export const DescriptionWithButtonPannel = styled(ItemDescription)(({ theme }) => ({
    paddingBottom: '0px',
}));

export function Chart({
    title,
    subTitle = '',
    options,
    ETFButtonsPannelButtonsProps,
    setChartRef,
    exportFileName,
    enableExportMenusAndTitle = true,
    customExports,
    exports,
}: {
    title?: string;
    subTitle?: string;
    options?: Highcharts.Options;
    ETFButtonsPannelButtonsProps?: Array<ETFButtonsPannelButtonProps>;
    setChartRef?: (value: any) => void;
    enableExportMenusAndTitle?: boolean;
    exportFileName?: string;
    customExports?: CustomExportsProps;
    exports?: {
        asOfDate: string;
        etfName?: string;
        columns: Map<string, ValueTypes>;
        ticker?: string;
    };
}) {
    // turn on export features
    exporting(Highcharts);
    HC_Data(Highcharts);
    offline(Highcharts);
    Boost(Highcharts);
    BoostCanvas(Highcharts);

    // need this for make custom export buttons work
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    useLayoutEffect(() => {
        if (setChartRef) setChartRef(chartComponentRef);
    }, [setChartRef]);

    // return chart with custom export button
    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            {enableExportMenusAndTitle ? (
                <HorizontalPanel>
                    <ChartTitle>{title}</ChartTitle>
                    <ExportMenus
                        chartRef={chartComponentRef}
                        analyticsCallback={(type: string) => {
                            globalThis.analytics?.registerAction?.({
                                action: getDownloadAction(type),
                                cardName: title,
                                dateRange: ETFButtonsPannelButtonsProps?.find((element) => element.isDefault)?.name,
                                reportType: type,
                                reportName: exportFileName,
                            });
                        }}
                        customExports={customExports}
                        headers={{ title, ...exports }}
                    />
                </HorizontalPanel>
            ) : null}
            {subTitle?.trim() === '' && typeof ETFButtonsPannelButtonsProps === 'undefined' ? null : (
                <HorizontalPanel>
                    {typeof ETFButtonsPannelButtonsProps !== 'undefined' ? (
                        <DescriptionWithButtonPannel>{subTitle}</DescriptionWithButtonPannel>
                    ) : (
                        <ChartDescription>{subTitle}</ChartDescription>
                    )}
                </HorizontalPanel>
            )}
            {typeof ETFButtonsPannelButtonsProps !== 'undefined' ? (
                <div style={{ textAlign: 'right', display: 'inline-block', width: '100%' }}>
                    <ETFButtonsPannel buttonsConfig={ETFButtonsPannelButtonsProps} />
                </div>
            ) : null}
            <HighchartsReact
                highcharts={Highcharts}
                options={Highcharts.merge(options, defaultOptions())}
                ref={chartComponentRef}
            />
        </Box>
    );
}
