import { SelectChangeEvent } from '@mui/material';
import { Categories, MarketTrendsDateRanges } from 'utils';

export const dataLimitForAssetCategory: number = 10;

export function getChangeEvents({
    setSelectedCategory,
    setSelectedDateRange,
    analyticsCategoryChangeCallback,
    analyticsDateRangeChangeCallback,
}: {
    setSelectedCategory: (value: Categories) => void;
    setSelectedDateRange: (value: MarketTrendsDateRanges) => void;
    analyticsCategoryChangeCallback: (category: Categories) => void;
    analyticsDateRangeChangeCallback: (dateRange: MarketTrendsDateRanges) => void;
}) {
    return {
        handleCategoryChange: (_event: React.SyntheticEvent<Element, Event>, newValue: number) => {
            const newCategory = Object.values(Categories)[newValue];
            analyticsCategoryChangeCallback(newCategory);
            setSelectedCategory(newCategory);
        },
        handleDateRangeChange: (event: SelectChangeEvent<unknown>) => {
            const newDateRange = event.target.value as MarketTrendsDateRanges;
            analyticsDateRangeChangeCallback(newDateRange);
            setSelectedDateRange(newDateRange);
        },
    };
}
