import Highcharts from 'highcharts';
import {
    AxisLabelsFormatterContextObject,
    LegendOptions,
    Options,
    SeriesOptionsType,
    TooltipFormatterContextObject,
    TooltipOptions,
} from 'highcharts/highstock';
import { fontFamilies } from 'utils';

export function defaultOptions(): Highcharts.Options {
    return {
        credits: {
            enabled: false,
        },
        accessibility: {
            enabled: false,
        },
    };
}

export const defaultFontSize = 14;

export function getDefaultFontStyle(fontSize: number = defaultFontSize) {
    return {
        fontFamily: fontFamilies.GraphikRegular,
        fontSize: `${fontSize}px`,
        color: '#57626a',
    };
}

export const defaultGridLineStyle = {
    gridLineColor: '#74828D',
    gridLineDashStyle: 'Dot',
    gridLineWidth: 0.7,
};

export const getTooltipHTML = (header: any, rows: any[] | any) => {
    if (!Array.isArray(rows)) {
        rows = [rows];
    }

    return `<tr>
        <th>
            <div style="text-align: left">${header}</div>
        </th>
    </tr>
    <tr>
        <td>
            ${(rows as Array<any>)
            .map(
                (row) => `<div class="leftPadding rightPadding">
                            ${row}
                        </div>`,
            )
            .join('')}
        </td>
    </tr>`;
};

export const getToolTipOptions = (
    useHTML: boolean,
    tooltipFormatter: (this: TooltipFormatterContextObject) => string,
): TooltipOptions => {
    let tooltipOptions = {};

    if (useHTML) {
        tooltipOptions = {
            backgroundColor: '#fff',
            borderRadius: 5,
            useHTML: true,
            borderWidth: 0,
            animation: false,
            padding: 0,
            style: { filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))' },
            valueSuffix: '%',
            headerFormat: '<table>',
            pointFormatter: tooltipFormatter,
            footerFormat: '</table>',
        };
    } else {
        tooltipOptions = {
            formatter: tooltipFormatter,
            shadow: true,
            animation: false,
            style: { ...getDefaultFontStyle(), fontWeight: 'bold' },
        };
    }
    return tooltipOptions as TooltipOptions;
};

export function getSpacingOptions([spacingTop, spacingRight, spacingBottom, spacingLeft]: Array<number | undefined>) {
    return { spacingTop, spacingRight, spacingBottom, spacingLeft }
}

export function getMarginOptions([marginTop, marginRight, marginBottom, marginLeft]: Array<number | undefined>) {
    return { marginTop, marginRight, marginBottom, marginLeft }
}

export function BarChartOptions({
    categories,
    exportFileName,
    columnHeaderFormatter,
    tooltipFormatter,
    turnOnLegend,
    turnOnDataLabels,
    dataLabelsFormatter,
    xAxisLabelsFormatter,
    resultSeries,
    tickInterval,
    minTick,
    maxTick,
    useHTML,
    plotOptionsBarGroupPadding = 0.2,
    plotOptionsBarPointWidth,
    chartHeight,
    plotOptionsAnimations
}: {
    categories: Array<string>;
    exportFileName?: string;
    columnHeaderFormatter?: (item: any, key: any) => string;
    tooltipFormatter: (this: TooltipFormatterContextObject) => string;
    turnOnLegend: boolean;
    turnOnDataLabels: boolean;
    dataLabelsFormatter: (this: TooltipFormatterContextObject) => string;
    xAxisLabelsFormatter?: (this: AxisLabelsFormatterContextObject) => string;
    resultSeries: Array<any>;
    tickInterval: number;
    minTick: number;
    maxTick: number;
    useHTML: boolean;
    plotOptionsBarGroupPadding?: number;
    plotOptionsBarPointWidth?: number;
    chartHeight?: number;
    plotOptionsAnimations?: boolean;
}): Options {
    return {
        chart: {
            spacing: [0, 20, 0, 0],
            height: chartHeight,
        },
        exporting: {
            chartOptions: {
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: turnOnDataLabels,
                        },
                    },
                    series: {
                        dataLabels: {
                            enabled: turnOnDataLabels,
                        },
                    },
                },
            },
            csv: {
                columnHeaderFormatter: columnHeaderFormatter,
            },
            enabled: false,
            fallbackToExportServer: false,
            filename: exportFileName,
        },
        legend: {
            enabled: turnOnLegend,
            x: 10, // put lengeng 10 pixels right, to make it center aligned (due to chart spacing right 20)
            itemStyle: {
                ...getDefaultFontStyle(),
                fontWeight: 'normal',
            },
            itemMarginTop: 0,
            itemMarginBottom: -4, // make 0px distance between bottom of the chart and legend
            padding: 5, // influence on the size of the legend
            margin: 30, // distance from legend to xAxis
            symbolPadding: 12, // padding between circle and legend item text
            symbolHeight: 18, // radius of circle
            useHTML: true, // need it to align symbol and item text vertically
            itemDistance: 24, // distance between items
        },
        plotOptions: {
            series: {
                animation: plotOptionsAnimations ?? true,
            },
            bar: {
                dataLabels: {
                    enabled: turnOnDataLabels,
                    formatter: dataLabelsFormatter,
                    align: 'left',
                    inside: false,
                    style: { ...getDefaultFontStyle(), fontWeight: 'normal' },
                },
                minPointLength: 2,
                groupPadding: plotOptionsBarGroupPadding, // distance between bar groups, default value 0.2
                pointWidth: plotOptionsBarPointWidth,
            },
        },
        series: resultSeries as Array<SeriesOptionsType>,
        title: {
            text: undefined,
        },
        tooltip: {
            ...getToolTipOptions(useHTML, tooltipFormatter),
        },
        xAxis: {
            categories: categories,
            labels: {
                align: 'left',
                formatter: xAxisLabelsFormatter,
                enabled: true,
                style: { ...getDefaultFontStyle(), textOverflow: 'none', whiteSpace: 'nowrap' },
                x: -28,
                y: defaultFontSize / 2, // make categories aligned vertically between two bars
                reserveSpace: true,
            },
            title: {
                text: undefined,
            },
            lineWidth: 0,
        },
        yAxis: {
            labels: {
                format: '{value}%',
                overflow: 'allow',
                padding: 5,
                style: { ...getDefaultFontStyle() },
                y: 0,
            },
            max: maxTick,
            min: minTick,
            tickInterval: tickInterval,
            title: {
                text: undefined,
            },
            offset: 16 + defaultFontSize, // 16px distance to the top of xAxis label plus 14px label
            ...defaultGridLineStyle,
        } as Highcharts.YAxisOptions,
    };
}

export function ColumnChartOptions({
    series,
    categories,
    useHTML,
    tooltipFormatter,
    exportingFileName,
    plotOptionsAnimations,
}: {
    series: any[];
    categories: string[];
    useHTML: boolean;
    tooltipFormatter: (this: TooltipFormatterContextObject) => string;
    exportingFileName: string;
    plotOptionsAnimations?: boolean;
}): Options {
    return {
        chart: {
            type: 'column',
            spacingBottom: 0,
        },
        yAxis: {
            labels: {
                format: '{value}%',
                style: { ...getDefaultFontStyle() },
                x: -28,
            },
            tickInterval: 10,
            title: {
                text: undefined,
            },
            ...defaultGridLineStyle,
        } as Highcharts.YAxisOptions,
        xAxis: {
            categories: categories,
            labels: {
                style: { ...getDefaultFontStyle() },
                y: 32,
            },
        },
        tooltip: useHTML
            ? getToolTipOptions(useHTML, tooltipFormatter)
            : {
                formatter: tooltipFormatter,
            },
        title: { text: undefined },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
            series: {
                animation: plotOptionsAnimations ?? true,
            }
        },
        series: series,
        exporting: {
            fallbackToExportServer: false,
            enabled: false,
            allowHTML: true,
            filename: exportingFileName,
        },
        legend: {
            itemStyle: {
                ...getDefaultFontStyle(),
                lineHeight: '18px',
                fontWeight: 'normal',
            },
            align: 'center',
            symbolPadding: 12,
            symbolHeight: 12,
            symbolWidth: 12,
            symbolRadius: 6,
            margin: 20,
            itemDistance: 24,
        },
        ...defaultOptions(),
    };
}

export function FlowChartOptions({
    series,
    tooltipFormatter,
    exportFileName,
    ticks,
    columnHeaderFormatter,
    xAxisLabelFormat,
    xAxisOrdinal,
    xAxisTickPositioner,
    yAxisLabelsFormatter,
    enableLegend = false,
    analyticsLegendCallback,
    plotOptionsAnimations
}: {
    series: any[];
    tooltipFormatter: (this: TooltipFormatterContextObject) => string;
    exportFileName: string;
    ticks: {
        max: number;
        min: number;
        tickerInterval: number;
    };
    columnHeaderFormatter: (item: any) => string;
    xAxisLabelFormat: string;
    xAxisOrdinal: boolean;
    xAxisTickPositioner: (this: any) => number[];
    yAxisLabelsFormatter: (this: Highcharts.AxisLabelsFormatterContextObject) => string;
    enableLegend: boolean;
    analyticsLegendCallback: (action: string) => void;
    plotOptionsAnimations?: boolean;
}): Options {
    // TODO: we should be adding all colors to a custom, centralized theme.ts palette, so we would be able to update them in one place for a whitelabeled implementation.
    const seriesColors: Array<string> = ['#007bb8', '#80bddc', '#5F9EA0'];

    const legend = (
        enableLegend
            ? {
                itemStyle: {
                    ...getDefaultFontStyle(),
                    // lineHeight: '18px',
                    fontWeight: 'normal',
                },
                align: 'center',
                x: 60,
                symbolPadding: 12,
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6,
                margin: 30,
                itemDistance: 24,
                enabled: true,
            }
            : { enabled: false }
    ) as LegendOptions;

    return {
        chart: {
            type: 'column',
            spacing: [10, 20, 10, 0],
        },
        legend: legend,
        exporting: {
            enabled: false,
            filename: exportFileName,
            csv: {
                columnHeaderFormatter: columnHeaderFormatter,
            },
        },
        series: enableLegend
            ? series.map((item, index) => {
                return {
                    data: item.data,
                    dataGrouping: {
                        enabled: false,
                    },
                    type: item.type,
                    name: item.name,
                    legendIndex: index,
                    color: seriesColors[index],
                    ...(item.type === 'column'
                        ? { pointWidth: undefined }
                        : {
                            lineWidth: 1,
                        }),
                };
            })
            : ([
                {
                    data: series,
                    dataGrouping: {
                        enabled: false,
                    },
                    type: 'column',
                    color: '#5F9EA0',
                    negativeColor: '#d12b10',
                },
            ] as any[]),
        title: {
            text: undefined,
        },
        plotOptions: {
            column: {
                minPointLength: 0,
                stacking: enableLegend ? 'normal' : undefined,
                borderWidth: 0,
            },
            spline: {
                marker: {
                    radius: 6,
                },
            },
            series: {
                events: {
                    legendItemClick: function (
                        this: Highcharts.Series,
                        event: Highcharts.SeriesLegendItemClickEventObject,
                    ) {
                        analyticsLegendCallback?.(
                            `legend clicks : ${this.name} : ${event.target.visible ? 'hide' : 'show'}`,
                        );
                    },
                },
                animation: plotOptionsAnimations ?? true,
            },
        },
        tooltip: {
            ...getToolTipOptions(true, tooltipFormatter),
            shared: true,
        },
        xAxis: {
            categories: undefined,
            labels: {
                enabled: true,
                format: xAxisLabelFormat,
                style: {
                    ...getDefaultFontStyle(),
                },
                y: 32,
            },
            lineWidth: 0,
            offset: 0,
            ordinal: xAxisOrdinal,
            startOnTick: true,
            tickPositioner: xAxisTickPositioner,
            type: 'datetime',
        },
        yAxis: {
            max: ticks.max,
            min: ticks.min,
            tickInterval: ticks.tickerInterval,
            labels: {
                format: '{value} %',
                overflow: 'allow',
                style: {
                    ...getDefaultFontStyle(),
                },
                formatter: yAxisLabelsFormatter,
                x: -28,
            },
            title: {
                text: undefined,
            },
            ...defaultGridLineStyle,
        } as Highcharts.YAxisOptions,
    };
}