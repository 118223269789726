import { Box } from '@mui/material';
import { ChartTitle } from 'components/Chart/Chart';
import ExportMenus, { CustomExportsProps, ExportMenusForMultipleCharts } from 'components/Chart/ExportMenus';
import { HorizontalPanel } from 'components/layout';
import { MarketTrendsDetailsLink } from 'features/marketTrends/routes/MarketTrendsRoutes';
import HighchartsReact from 'highcharts-react-official';
import { ValueTypes } from 'utils/valuesFormatter';
import { MarketTrend } from '../../../types/market';

export function MarketTrendsChartsTopPanel({
    title,
    chartRef,
    detailsPathName,
    multipleExportProps,
    analyticsCallback,
    customExports,
    exports,
}: {
    title: string;
    detailsPathName?: MarketTrend;
    chartRef?: React.RefObject<HighchartsReact.RefObject | null>;
    multipleExportProps?: {
        subChartsProps: Array<{
            chartRefObject?: React.RefObject<HighchartsReact.RefObject>;
            chartHeader: string;
            columnsToFormatting: Map<string, ValueTypes>;
        }>;
        exportFileName: string;
        exportHeaders?: Array<string>;
    };
    analyticsCallback?: (type: string) => void;
    customExports?: CustomExportsProps;
    exports?: {
        asOfDate: string;
        ticker?: string;
        etfName?: string;
        columns?: Map<string, ValueTypes>;
    };
}) {
    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            <HorizontalPanel>
                <ChartTitle>{title}</ChartTitle>
                <div style={{ flexGrow: 1 }} />
                {detailsPathName && (
                    <MarketTrendsDetailsLink
                        pathName={detailsPathName}
                        analyticsCallback={() =>
                            globalThis.analytics?.registerAction?.({
                                action: 'chart details',
                                cardName: title,
                            })
                        }
                    />
                )}
                {multipleExportProps ? (
                    <ExportMenusForMultipleCharts
                        multipleExport={{
                            fileName: multipleExportProps.exportFileName,
                            exportHeaders: {
                                columnHeaders: multipleExportProps.exportHeaders || [],
                                title: title,
                                asOfDate: exports?.asOfDate || '',
                            },
                            chartsProps: multipleExportProps.subChartsProps.map((value) => {
                                return {
                                    chartRef: value.chartRefObject,
                                    worksheetName: value.chartHeader,
                                    columnsToFormatting: value.columnsToFormatting,
                                };
                            }),
                        }}
                        analyticsCallback={analyticsCallback}
                        customExports={customExports}
                    />
                ) : chartRef ? (
                    <ExportMenus
                        chartRef={chartRef}
                        analyticsCallback={analyticsCallback}
                        customExports={customExports}
                        headers={{ title, ...exports }} />
                ) : null}
            </HorizontalPanel>
        </Box>
    );
}
