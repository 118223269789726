import { addAsOfDateToHighchart, addLogoToHighchart, exportChartAsImgWithFonts, getExportDefaultChartOptions } from 'components/Chart/Export';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import {
    HistoricalStackedPercentage,
    getSeries,
    getUniqueKeys,
    sortSeriesByList,
} from 'components/Chart/HistoricalStackedPercentage';
import { getMarginOptions, getSpacingOptions, getTooltipHTML } from 'components/Chart/Options';
import { DataAsOfDate, ETFCard, ETFEmptyCard } from 'components/layout';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { ValueTypes, buildChartStacks } from 'utils';
import { calcPercentChange, formatPercentages, getExportingFileName } from 'utils/valuesFormatter';
import { getSectorExposureHistorical } from '../api/etfDetailsData';
import { ConstituentType, ETFDetailsParams, SectorExposureData } from '../types/research';

export default function SectorExposureHistorical({
    companyData,
    numberOfYears = 5,
    componentRendered,
}: {
    companyData: ETFDetailsParams;
    numberOfYears?: number;
    componentRendered?: boolean;
}) {
    let sectorExposureHistoricalQueryResult = getSectorExposureHistorical({
        ticker: companyData.ticker,
        exchange: companyData.exchange,
        numberOfYears: numberOfYears,
    });

    if (sectorExposureHistoricalQueryResult.isLoading) {
        return <ETFCard isLoading={sectorExposureHistoricalQueryResult.isLoading} />;
    }
    const cardTitle = 'Sector Exposure Over Time';
    const subTitle = 'Historical breakdown of ETF holdings by GICS sector';
    // return EmptyCard if no data
    if (
        !(
            sectorExposureHistoricalQueryResult.data &&
            sectorExposureHistoricalQueryResult.data.sectors &&
            sectorExposureHistoricalQueryResult.data.sectors.length > 0 &&
            sectorExposureHistoricalQueryResult.data.sectors.some((x) => Number.isFinite(x.agg_weighting))
        )
    )
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;

    let sectorExposureData: Array<SectorExposureData> = sectorExposureHistoricalQueryResult.data.sectors;
    const series = getSeries(sectorExposureData, ['gics_name', 'year_end'], 'agg_weighting').map((element) => {
        // change weightings format to percentage
        return { ...element, data: element.data.map((item) => formatPercentages(item)) };
    });
    const categories = getUniqueKeys(sectorExposureData, 'year_end');
    const constituentTypes: Record<string, Array<ConstituentType>> = sectorExposureData
        .filter((element) => element.constituent_types.length > 0)
        .reduce((accumulator: any, currentValue) => {
            accumulator[currentValue.year_end] = currentValue.constituent_types.map((element) => {
                return {
                    ...element,
                    // change constituent types weightings format to percentage
                    agg_weighting: formatPercentages(element.agg_weighting),
                };
            });
            return accumulator;
        }, {});

    sortSeriesByList(series, series.map((item) => item.name).concat(['Other', 'Unclassified']));

    const tooltipFormatter = function (this: any): string {
        const getFormattedValue = (unformattedValue: number | null) =>
            unformattedValue !== null && unformattedValue !== 0 ? unformattedValue.toFixed(2) : unformattedValue;

        const getOtherToolTipRow = () =>
            constituentTypes[this.category].map(
                (element) => `<span>${element.type_name}: ${getFormattedValue(element.agg_weighting)}%</span>`,
            );

        const getToolTipRow = (): Array<string> => {
            let prevPoint = this.x === 0 ? null : this.series.data[this.x - 1];
            let changePercentage: string = '';

            if (prevPoint?.y) {
                changePercentage = `<span><span class="dot" /></span><span>YoY % Change: ${calcPercentChange(
                    prevPoint.y,
                    this.y,
                    2,
                )}%</span>`;
            }

            return [
                `<span><span class="dot" style="background-color: ${this.series.color}"></span></span>` +
                `<span>${this.series.name}: ${getFormattedValue(this.y as number)}%</span>`,
                changePercentage,
            ];
        };

        return getTooltipHTML(this.category, [
            ...getToolTipRow(),
            ...(this.series.name === 'Other' ? getOtherToolTipRow() : []),
        ]);
    };

    const asOfDate = sectorExposureData
        .map((item) => item.as_of_date)
        .sort()
        .reverse()[0];

    const customExportsProps: CustomExportsProps = [
        {
            type: 'JPEG',
            callback: ({ chartRef }) => exportSectorExposureHistoricalJpegChart({
                chartRef: chartRef.current,
                title: cardTitle,
                subTitle,
                ticker: companyData.ticker,
                asOfDate
            })
        }
    ]

    const columns: Map<string, ValueTypes> = new Map<string, ValueTypes>([['Category', ValueTypes.Integer]]);
    series.map((item) => {
        columns.set(item.name, ValueTypes.Percentage);
        return '';
    });

    return (
        <ETFCard containerStyles={{ position: 'relative' }}>
            <HistoricalStackedPercentage
                title={cardTitle}
                subTitle={subTitle}
                series={buildChartStacks(series, (action: string) => {
                    globalThis.analytics?.registerAction?.({
                        action: action,
                        cardName: cardTitle,
                    });
                })}
                categories={categories}
                exportingFileName={getExportingFileName(cardTitle, companyData.ticker, companyData.exchange)}
                tooltipFormatter={tooltipFormatter}
                plotOptionsAnimations={componentRendered ? false : true}
                customExports={customExportsProps}
                exports={{
                    asOfDate: asOfDate,
                    ticker: companyData.ticker,
                    etfName: companyData.composite_name,
                    columns: columns,
                }}
            />
            <DataAsOfDate date={asOfDate} />
        </ETFCard>
    );
}


async function exportSectorExposureHistoricalJpegChart(props: { chartRef: HighchartsReact.RefObject, title: string, subTitle: string, ticker: string, asOfDate: string }) {
    const { chartRef, title, subTitle, ticker, asOfDate } = props;
    const chart: Highcharts.Chart = chartRef.chart;

    const defaultChartOptions: Highcharts.Options = getExportDefaultChartOptions({ title, subtitle: subTitle, ticker });

    const chartOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            ...getSpacingOptions([25, 45, 40, 40]),
            ...getMarginOptions([100]),
            height: 500,
            width: 1000,
            events: {
                load: function (this: Highcharts.Chart) {
                    addLogoToHighchart(this);
                    addAsOfDateToHighchart(this, asOfDate);
                }
            }
        }
    }

    exportChartAsImgWithFonts(chart, chartOptions);
}
