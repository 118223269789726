import { waitUntil } from 'utils';

class PrefetchHelper {
    private callbackArray: Array<() => void>;
    private runningStatus: boolean;

    constructor() {
        this.callbackArray = [];
        this.runningStatus = false;
    }

    private runWaitingHandler() {
        this.runningStatus = true;
        waitUntil(
            () => document.getElementsByClassName('analytics-cfra-skeleton').length === 0,
            [
                () => {
                    const callbackArray = this.callbackArray;
                    this.callbackArray = [];
                    this.runningStatus = false;

                    callbackArray.forEach((func) => func());
                },
            ],
        );
    }

    public runWhenNoSkeletons(callback: () => void) {
        this.callbackArray.push(callback);
        if (!this.runningStatus) {
            this.runWaitingHandler();
        }
    }
}

export const prefetchHelper = new PrefetchHelper();
