import { Box, styled } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Page } from 'analytics/Page';
import Feature1Icon from 'assets/images/ETFFeature1Icon.svg';
import Feature2Icon from 'assets/images/ETFFeature2Icon.svg';
import Feature3Icon from 'assets/images/ETFFeature3Icon.svg';
import ETFLatestResearchImage from 'assets/images/ETFLatestResearchImage.png';
import { ETFPageContainer } from 'components/layout/ETFPageContainer';
import { homePageTheme } from 'components/themes/theme';
import { FeatureList } from '../components/FeatureList';
import { HeroSection } from '../components/HeroSection/HeroSection';
import { LatestResearch } from '../components/LatestResearch';

const FEATURES_AND_BENEFITS = [
    { img: Feature1Icon, text: 'Identify new product or investment opportunities based on current flow trends.' },
    {
        img: Feature2Icon,
        text: 'Get insight into the true exposure of your product or investment as well as comparable ETFs.',
    },
    { img: Feature3Icon, text: 'Understand and break down ETF asset size based on market movement and new flows.' },
];

const LATEST_RESEARCH_AND_RESOURCES = {
    latestResearch: {
        image: ETFLatestResearchImage,
        title: 'ETF Inflows Significantly Lower in Q1 2023',
        description:
            'Net inflows into U.S.-listed ETFs were down significantly in Q1 2023 relative to the corresponding period in the last two years.',
        pdfUrl: 'pdfs/US_ETF_Market_April_2023.pdf',
    },
    relatedResources: [
        {
            title: 'CFRA Data Definitions',
            description: 'CFRAs classification framework and category definitions.',
            pdfUrl: 'pdfs/CFRA_FUNDynamix_Category_Definitions.pdf',
        },
    ],
};

export function Home() {
    const HeroSectionContainer = styled(Box)(({ theme }) => ({
        padding: '90px 24px',
        backgroundColor: '#F2F5FC',
        maxWidth: '1366px',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            padding: '90px 60px',
        },
    }));

    const FeatureListContainer = styled(Box)(({ theme }) => ({
        padding: '28px 24px 84px 24px',
        backgroundColor: '#F2F5FC',
        maxWidth: '1366px',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            padding: '28px 60px 84px 60px',
        },
    }));

    const LatestResearchContainer = styled(Box)(({ theme }) => ({
        padding: '72px 24px 72px 24px',
        maxWidth: '1366px',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            padding: '72px 60px 116px 60px',
        },
    }));

    const RelatedResourcesBgStretch = styled(Box)(() => ({
        display: 'block',
        position: 'absolute',
        background: '#F2F5FC',
        top: '72px',
        right: 0,
        height: 'calc(100% - 188px)',
        width: 'calc(max(0px, calc((100vw - 1486px)/2)) + 100px)',
        '@media (max-width: 1486px)': {
            display: 'none',
        },
    }));

    return (
        <Page name='home'>
            <ThemeProvider theme={homePageTheme}>
                <ETFPageContainer containerStyles={{ maxWidth: 'unset', backgroundColor: 'unset' }}>
                    <HeroSectionContainer component='section'>
                        <HeroSection />
                    </HeroSectionContainer>

                    <FeatureListContainer component='section'>
                        <FeatureList title='Platform Features & Benefits' features={FEATURES_AND_BENEFITS} />
                    </FeatureListContainer>

                    <Box component='section' bgcolor='#fff'>
                        <Box position='relative'>
                            <RelatedResourcesBgStretch />
                            <LatestResearchContainer>
                                <LatestResearch
                                    latestResearch={LATEST_RESEARCH_AND_RESOURCES.latestResearch}
                                    relatedResources={LATEST_RESEARCH_AND_RESOURCES.relatedResources}
                                />
                            </LatestResearchContainer>
                        </Box>
                    </Box>
                </ETFPageContainer>
            </ThemeProvider>
        </Page>
    );
}
