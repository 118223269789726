export enum TabName {
    HoldingsAndExposure = 'Holdings & Exposure',
    FlowsAndPerformance = 'Flows & Performance',
}

export const enum Component {
    FundDetails = 'Fund details',
    SectorExposureOverTime = 'Sector Exposure Over time',
    SectorExposure = 'Sector Exposure',
    CreditExposureOvertime = 'CreditExposure Over time',
    CreditExposure = 'Credit Exposure',
    MaturityExposure = 'Maturity Exposure',
    TopHoldings = 'Top Holdings',
    SectionDividerFlowsPerformance = 'Flows & Performance',
    FundFlows = 'Fund Flows',
    FundVsMarketMovement = 'Fund vs Market Movement',
    ETFPerformance = 'ETF Performance',
    KeyStatistics = 'Key Statistics ',
    Overview = 'Overview (currently Fund Profile)',
}
