import useMediaQuery from '@mui/material/useMediaQuery';
import { getDownloadAction } from 'analytics/utils';
import { BarChart } from 'components/Chart';
import { addAsOfDateToHighchart, addLogoToHighchart, exportChartAsImgWithFonts, getExportDefaultChartOptions } from 'components/Chart/Export';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import { getMarginOptions, getSpacingOptions, getTooltipHTML } from 'components/Chart/Options';
import { DataAsOfDate, ETFCard, ETFEmptyCard } from 'components/layout';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { AxisLabelsFormatterContextObject } from 'highcharts/highstock';
import React, { useRef } from 'react';
import { ValueTypes, extractTickerFromParenthesis } from 'utils';
import { getKeyAssetClassesData } from '../../api/market';
import { MarketTrend } from '../../types/market';
import { MarketTrendsChartsTopPanel } from './shared/MarketTrendsChartsTopPanel';

export const tickerExchanges = [
    { ticker: 'SPY', exchange: 'ARCX' },
    { ticker: 'IWM', exchange: 'ARCX' },
    { ticker: 'AGG', exchange: 'ARCX' },
    { ticker: 'EFA', exchange: 'ARCX' },
    { ticker: 'EEM', exchange: 'ARCX' },
    { ticker: 'DBC', exchange: 'ARCX' },
    { ticker: 'GLD', exchange: 'ARCX' },
];

// create matching between ticker and label to display
export const tickerDisplayName: Record<string, string> = {
    SPY: 'US Large Cap (SPY)',
    IWM: 'US Small Cap  (IWM)',
    AGG: 'US Bonds (AGG)',
    EFA: 'Developed Equity (EFA)',
    EEM: 'Emerging Equity (EEM)',
    DBC: 'Commodities Futures (DBC)',
    GLD: 'Gold  (GLD)',
};

export default function KeyAssetClasses() {
    const isBelow500px = useMediaQuery('(max-width:500px)');
    const [chartRef, setChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject | null>>(useRef(null));
    // getting UseQueryResult object with data for KeyAssetClasses chart
    const keyAssetClassesDataQueryResult = getKeyAssetClassesData({
        tickerExchanges: tickerExchanges,
    });

    // show card loading if data still loading
    if (keyAssetClassesDataQueryResult.isLoading) {
        return <ETFCard isLoading={keyAssetClassesDataQueryResult.isLoading} />;
    }
    const cardTitle = 'Key Asset Class Performance';
    const subTitle = 'Trailing total returns for benchmark ETFs in major asset classes';
    // Show Nothing if no data
    if (!(keyAssetClassesDataQueryResult.data && keyAssetClassesDataQueryResult.data.length > 0))
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;
    // cut off UseQueryResult attributes, extract only KeyAssetClasses data
    let keyAssetClassesData = keyAssetClassesDataQueryResult.data;

    // copy array before sorting
    // https://stackoverflow.com/questions/64957735/typeerror-cannot-assign-to-read-only-property-0-of-object-object-array-in
    const keyAssetClassesDataSorted = [...keyAssetClassesData];

    // sort the data the exact order as in the keyAssetClassesData template
    keyAssetClassesDataSorted.sort((a, b) => {
        const tickers = tickerExchanges.map((val) => val.ticker);
        return tickers.lastIndexOf(a.composite_ticker) - tickers.lastIndexOf(b.composite_ticker);
    });

    // create function for setting columns names in csv export
    const columnHeaderFormatter = function (item: Highcharts.DataLabelsFormatterCallbackFunction) {
        if (item instanceof Highcharts.Axis && item.isXAxis) return 'Key Asset Classes';
        return item.name;
    };
    // set categories to display in chart
    const categories: Array<string> = keyAssetClassesDataSorted.map((val) => {
        if (Object.keys(tickerDisplayName).includes(val.composite_ticker))
            return tickerDisplayName[val.composite_ticker];
        // if no label for this ticker, just show ticker
        return val.composite_ticker;
    });

    const series = [
        {
            name: '1 Month',
            data: keyAssetClassesDataSorted.map((val) => val.performance.month_1_return),
            color: '#80bddc',
        },
        {
            name: '3 Month',
            data: keyAssetClassesDataSorted.map((val) => val.performance.month_3_return),
            color: '#007bb8',
        },
    ];

    function tooltipFormatter(this: Highcharts.TooltipFormatterContextObject & { category?: string }) {
        // category missed in TooltipFormatterContextObject
        let resultY;
        if (this.y !== 0) resultY = this.y?.toFixed(2);
        else resultY = this.y;
        return getTooltipHTML(this.category, [`${this.series.getName()}: ${resultY}%`]);
    }

    function xAxisLabelsFormatter(this: AxisLabelsFormatterContextObject) {
        if (isBelow500px) {
            return extractTickerFromParenthesis(String(this.value));
        }

        return String(this.value);
    }

    const asOfDate = keyAssetClassesDataSorted
        .map((value) => value.performance.as_of_date)
        .sort()
        .reverse()[0];

    const exportFileName = 'key-asset-classes-performance-chart';

    const customExportsProps: CustomExportsProps = [
        {
            type: 'JPEG',
            callback: () => exportKeyAssetClassJpegChart({
                chartRef: chartRef.current,
                title: cardTitle,
                subTitle,
                asOfDate
            })
        }
    ]
    const columns: Map<string, ValueTypes> = new Map<string, ValueTypes>([['Key Asset Classes', ValueTypes.Text]]);
    series.map((item) => {
        columns.set(item.name, ValueTypes.Percentage);
        return '';
    });

    return (
        <ETFCard containerStyles={{ position: 'relative' }}>
            <MarketTrendsChartsTopPanel
                title={cardTitle}
                chartRef={chartRef}
                detailsPathName={MarketTrend.KeyAssetClassesDetails}
                analyticsCallback={(type: string) => {
                    globalThis.analytics?.registerAction?.({
                        action: getDownloadAction(type),
                        cardName: cardTitle,
                        reportType: type,
                        reportName: exportFileName,
                    });
                }}
                customExports={customExportsProps}
                exports={{
                    asOfDate: asOfDate,
                    etfName: '',
                    columns: columns,
                    ticker: '',
                }}
            />
            <BarChart
                categories={categories}
                series={series}
                subTitle={subTitle}
                exportFileName={exportFileName}
                columnHeaderFormatter={columnHeaderFormatter}
                tooltipFormatter={tooltipFormatter}
                xAxisLabelsFormatter={xAxisLabelsFormatter}
                turnOnLegend={true}
                turnOnDataLabels={false}
                enableExportMenusAndTitle={false}
                setChartRef={setChartRef}
                useHTML={true}
                plotOptionsBarGroupPadding={0.16}
                plotOptionsBarPointWidth={14}
                chartHeight={410}
            />
            <DataAsOfDate date={asOfDate} />
        </ETFCard>
    );
}

async function exportKeyAssetClassJpegChart(props: { chartRef: HighchartsReact.RefObject | null, title: string, subTitle: string, asOfDate: string }) {
    const { chartRef, title, subTitle, asOfDate } = props;

    if (chartRef?.chart) {
        const chart: Highcharts.Chart = chartRef.chart;

        const defaultChartOptions: Highcharts.Options = getExportDefaultChartOptions({ title, subtitle: subTitle });

        const chartOptions: Highcharts.Options = {
            ...defaultChartOptions,
            chart: {
                ...defaultChartOptions.chart,
                ...getSpacingOptions([25, 45, 40, 40]),
                ...getMarginOptions([100, undefined, 110]),
                height: 500,
                width: 700,
                events: {
                    load: function (this: Highcharts.Chart) {
                        addLogoToHighchart(this);
                        addAsOfDateToHighchart(this, asOfDate);
                    }
                }
            }
        }

        exportChartAsImgWithFonts(chart, chartOptions);
    }
}