import { ThemeProvider } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { getDownloadAction } from 'analytics/utils';
import ETFModal from 'components/ETFModal';
import { exportAgGrid } from 'components/excelExport/export';
import { overlayModalTheme } from 'components/themes/theme';
import { colDefType } from 'components/types/agGrid';
import { useRef } from 'react';
import { DrillDownProps } from '../shared/types';
import { EtfsGridCard, EtfsGridCardProps } from './EtfsGridCard';

type EtfsOverlayProps = {
    categoryLevel: string;
    categoryName: string;
    cardName?: string;
    getOpenComponent?: (handleOpen: () => void) => React.ReactElement;
    callbackOnClose?: () => void;
    drillDownProps?: DrillDownProps | null;
} & EtfsGridCardProps;

export function EtfsOverlay({
    categoryLevel,
    categoryName,
    getOpenComponent,
    cardName,
    label,
    lid,
    dateRange,
    callbackOnClose,
    drillDownProps,
}: EtfsOverlayProps) {
    const overlayGridRef = useRef<AgGridReact<colDefType>>(null);
    const agGridExportProps = {
        fileName: `${categoryName} ETF List`,
        sheetName: 'ETF List',
        metadata: {
            cardName: categoryLevel + ' ' + categoryName,
        },
        asOfDateField: 'data_returns_flow.as_of_date',
        useMaxAsOfDate: true,
        autoSizeColumns: true,
    };

    const overlayExportCallback = () => exportAgGrid(overlayGridRef, agGridExportProps);

    const title = `${categoryLevel}: ${categoryName}`;

    const analyticsSelectedCategory = drillDownProps ? 'no categories selected' : categoryLevel;
    const analyticsCardName = `${cardName || label} : etfs overlay : ${title}`;

    return (
        <ThemeProvider theme={overlayModalTheme}>
            <ETFModal
                title={title}
                titleStyle={{ paddingBottom: '12px', color: '#002B5A', fontSize: '18px' }}
                description={'ETFs belonging to the selected category'}
                descriptionStyle={{ paddingBottom: '36px' }}
                titleAndDescriptionContainerStyle={{ padding: '28px', paddingBottom: '0px' }}
                closeButtonFontSize={30}
                getOpenComponent={getOpenComponent}
                exportCallback={overlayExportCallback}
                callbackOnClose={callbackOnClose}
                analyticsExportCallback={(type: string) => {
                    globalThis.analytics?.registerAction?.({
                        action: getDownloadAction(type),
                        cardName: analyticsCardName,
                        dateRange: dateRange,
                        reportType: type,
                        reportName: agGridExportProps.fileName,
                        selectedCategory: analyticsSelectedCategory,
                    });
                }}
                analyticsOpenModalCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `open etfs overlay : ${title}`,
                        cardName: cardName ? cardName : label,
                        dateRange: dateRange,
                        selectedCategory: analyticsSelectedCategory,
                    });
                }}>
                <EtfsGridCard
                    categoryLevel={categoryLevel}
                    lid={lid}
                    label={label}
                    ref={overlayGridRef}
                    dateRange={dateRange}
                    maxGridContainerHeightPercentage={90} // max modal height in percentage
                    analyticsCardName={analyticsCardName}
                />
            </ETFModal>
        </ThemeProvider>
    );
}
