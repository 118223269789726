import { ETFInfoButton } from 'components/ETFButton/ETFInfoButton';
import ETFModal from 'components/ETFModal';
import { useCallback } from 'react';

type Props = {
    title: string;
    description: string | React.ReactNode;
    buttonFontSize?: number;
    analyticsOpenModalCallback?: () => void;
};

export function ETFInfo({ title, description, buttonFontSize, analyticsOpenModalCallback }: Props) {
    const getOpenModalButton = useCallback(
        (handleOpen: () => void) => {
            return <ETFInfoButton onClick={handleOpen} buttonFontSize={buttonFontSize} />;
        },
        [buttonFontSize],
    );

    return (
        <ETFModal
            getOpenComponent={getOpenModalButton}
            title={title}
            description={description}
            analyticsOpenModalCallback={analyticsOpenModalCallback}
        />
    );
}
