import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import HighchartsReact from 'highcharts-react-official';

export function useHighchartsWidth(chartRef: React.RefObject<HighchartsReact.RefObject> | undefined) {
    const [chartWidth, setChartWidth] = useState(0);

    const handleResize: () => void = debounce(() => {
        if (chartRef?.current?.chart?.chartWidth) {
            setChartWidth(chartRef.current.chart.chartWidth);
        }
    }, 500);

    useEffect(() => {
        if (chartRef?.current?.chart?.chartWidth) {
            setChartWidth(chartRef.current.chart.chartWidth);
        }
    }, [chartRef, chartRef?.current?.chart?.chartWidth]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [chartRef?.current?.chart?.chartWidth, handleResize]);

    return chartWidth;
}