import { Box, Link, Stack, styled } from '@mui/material';
import HeaderImage from 'assets/images/ETFMainBannerHeader.svg';
import { Button } from 'components/ETFButton';
import Typography from 'components/dataDisplay/Typography';
import Grid from 'components/layout/Grid';
import { Link as RouterLink } from 'react-router-dom';
import { fontFamilies } from 'utils';

export function HeroSection() {
    const RoundedButton = styled(Button)({
        padding: '14px',
        borderRadius: '25px',
        color: '#fff',
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '15px',
        backgroundColor: '#007BB8',
        letterSpacing: '1.5px',
        borderColor: '#007BB8',
        minWidth: '50%',
        '&:hover': {
            backgroundColor: '#002B5A',
            borderColor: '#002B5A',
        },
    });

    const getStartedButtonText = 'GET STARTED';

    return (
        <Box component='div'>
            <Grid container columnSpacing={2} rowSpacing={8}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack spacing={4}>
                        <Typography variant='h1' textAlign={'left'}>
                            A dynamic data platform designed by ETF pros for ETF pros
                        </Typography>
                        <Typography variant='subtitle1' textAlign={'left'}>
                            Quickly gain real-time insight and analyze trends for the global ETF market and individual
                            funds
                        </Typography>
                        <Box component='div'>
                            <Link
                                component={RouterLink}
                                to='/market-trends'
                                underline='none'
                                onClick={() =>
                                    globalThis.analytics?.registerAction?.({
                                        action: getStartedButtonText.toLocaleLowerCase(),
                                    })
                                }>
                                <RoundedButton>{getStartedButtonText}</RoundedButton>
                            </Link>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} display='flex'>
                    <Box component='img' src={HeaderImage} width='100%' height='auto' />
                </Grid>
            </Grid>
        </Box>
    );
}
