import { SxProps } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ItemText, defaultEmptyCardText } from 'components/layout';
import { breakpointsTheme } from 'components/themes/theme';
import { colDefType } from 'components/types/agGrid';
import { MarketTrend } from 'features/marketTrends/types/market';
import { Categories } from 'utils';

export function setCustomlyFlexWidths(isBelowLg: boolean, customlyFlexibleColumns: Array<string>) {
    return (gridRef: React.RefObject<AgGridReact<colDefType>>) => {
        if (!gridRef.current) return;
        const getWidth = (minWidth: number, maxWidth: number, allColumnsNames: Array<string>) => {
            if (isBelowLg) minWidth = 170;
            const columnsIntersection = allColumnsNames.filter((x) => customlyFlexibleColumns.includes(x));
            let width = isBelowLg
                ? maxWidth -
                  (breakpointsTheme.breakpoints.values['lg'] - globalThis.window.innerWidth) /
                      columnsIntersection.length
                : maxWidth;

            if (width < minWidth) return minWidth;
            if (width > maxWidth) return maxWidth;
            return width;
        };
        const columnDefs = gridRef.current?.api.getColumnDefs() as Array<ColDef<colDefType>>;
        if (!columnDefs) return;
        const allColumnsNames: Array<string> = [];
        columnDefs.forEach((element) => {
            if (element.headerName) {
                allColumnsNames.push(element.headerName);
            }
        });
        gridRef.current?.api.setColumnDefs(
            columnDefs.map((columnDef) => {
                if (
                    !(
                        columnDef.headerName &&
                        columnDef.minWidth &&
                        columnDef.maxWidth &&
                        allColumnsNames.length > 0 &&
                        customlyFlexibleColumns.includes(columnDef.headerName)
                    )
                ) {
                    return columnDef;
                }

                return {
                    ...columnDef,
                    width: getWidth(columnDef.minWidth, columnDef.maxWidth, allColumnsNames),
                };
            }),
        );
    };
}

export function getMinWidthForHeader(headerName: string): number {
    const defaultMinWidth = 120;
    let resultMinWidth = 0;

    const dateRangesToMinWidth: Record<string, number> = {
        '1 Month': 135,
        '1 Year': 120,
        '3 Month': 135,
        YTD: 120,
    };

    const dateRange = Object.keys(dateRangesToMinWidth).find((dateRange) => headerName.indexOf(dateRange) > -1);

    if (dateRange) {
        resultMinWidth += dateRangesToMinWidth[dateRange];
        headerName = headerName.replace(dateRange, '');
    }

    const headerNameToMinWidth: Record<string, number> = {
        ' Flows': 30,
        ' Flows to Asset': 65,
        ' Flows to Asset Ratio': 80,
        ' Performance': 70,
        ' Return': 25,
        'Asset Class': 140,
        'CFRA Category': 165,
        'CFRA Sub-category': 175,
        'ETF Name': 140,
        'Number of ETFs': 160,
        Sector: 135,
        Sponsor: 160,
        Ticker: 140,
        'Total Assets': 150,
    };

    if (Object.keys(headerNameToMinWidth).includes(headerName)) {
        resultMinWidth += headerNameToMinWidth[headerName];
    }

    return resultMinWidth === 0 ? defaultMinWidth : resultMinWidth;
}

export function NoInformationAvailable({ sx }: { sx?: SxProps }) {
    return <ItemText sx={{ paddingBottom: '28px', paddingLeft: '28px', ...sx }}>{defaultEmptyCardText}</ItemText>;
}

export function Link(props: { value: string; handleOpen?: () => void }) {
    // use eslint-disable-next-line to avoid the warning - The href attribute is required for an anchor to be keyboard accessible.
    // we don't need href here, use <a>, but not <button> to same ag grid ellipsis behavior
    return (
        // eslint-disable-next-line
        <a
            onClick={props.handleOpen}
            style={{
                color: '#007bb8',
                cursor: 'pointer',
            }}>
            {props.value}
        </a>
    );
}

export function getNextLevelCategory(category: Categories) {
    const categoryIndex = Object.values(Categories).indexOf(category);
    if (categoryIndex === -1 || categoryIndex === Object.values(Categories).length - 1) {
        throw new Error(`Impossible to get next level category for - ${category}.`);
    }
    return Object.values(Categories)[categoryIndex + 1];
}

export function getAsOfDatePath(label: MarketTrend){
    switch (label) {
        case MarketTrend.SectorPerformanceDetails:
        case MarketTrend.KeyAssetClassesDetails:
            return "performance.as_of_date"
        default:
            return "as_of_date";
    }
}

export function getAsOfDateMax(label: MarketTrend){
    switch (label) {
        case MarketTrend.SectorPerformanceDetails:
        case MarketTrend.KeyAssetClassesDetails:
            return true
        default:
            return false;
    }
}

export function getMarketTrendsDetailsCardDescription(label: MarketTrend) {
    switch (label) {
        case MarketTrend.FlowsToAssetsDetails:
            return 'View ETF categories, assets and asset flows, flow-to-asset ratios and other key fund metrics';
        case MarketTrend.PerformanceDetails:
            return 'View high-performing ETFs by category, asset class and trailing total returns';
        case MarketTrend.KeyAssetClassesDetails:
            return 'Trailing total returns for benchmark ETFs in major asset classes';
        case MarketTrend.SectorPerformanceDetails:
            return 'Trailing total returns for benchmark sector ETFs';
        default:
            throw new Error(`label ${label} doesn't exist.`);
    }
}
