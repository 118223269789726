import { AgGridReact } from 'ag-grid-react';
import { ColumStyle } from 'components/layout/types';
import { colDefType } from 'components/types/agGrid';
import { Column, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { get, maxBy } from 'lodash';
import { ValueTypes, getMomentObjectFrom, getPercentageValue, todayDateStringUS } from 'utils';
import { ExcelCustomFields, ExcelMetadata, ExcelTable, TableProps } from './type';
import logoFile from '../../../src/assets/images/FUNDynamixLogo.png';

export const setDisclaimer = (workbook: Workbook) => {
    let worksheet = workbook.addWorksheet('Disclaimer');

    worksheet.addRows([
        [
            ' S&P GLOBAL™ is used under license. The owner of this trademarks is S&P Global Inc. or its affiliate, ' +
                'which are not affiliated with CFRA Research or the author of this content. Copyright ©  2023 CFRA. All' +
                ' rights reserved. Certain information is copyright ©2023, S&P Global Market Intelligence LLC (and its a' +
                'ffiliates as applicable). All rights reserved. Because of the possibility of human or mechanical error,' +
                ' any data contained herein is provided "AS IS" and CFRA does not guarantee the accuracy, adequacy, comple' +
                'teness or availability of any information and is not responsible for any errors or omissions  or for the ' +
                'results obtained from  the use of such information. CFRA GIVES NO EXPRESS OR IMPLIED WARRANTIES OF ANY KI' +
                'ND, INCLUDING,  BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE' +
                ' OR USE. IN NO EVENT SHALL CFRA BE LIABLE TO ANY PERSON OR ENTITY FOR ANY LIABILITY  WHATSOEVER  OR ANY D' +
                'IRECT, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH OR RESULTING  FROM ANY USE HEREOF. R' +
                'EPRODUCTION OF ANY DATA CONTAINED HEREIN IN ANY FORM AND/OR DISSEMINATION,  DISTRIBUTION, RESALE OR STORA' +
                'GE FOR SUBSEQUENT USE OF ANY SUCH PURPOSE BY ANY MEANS,  WHATSOEVER, OF ANY DATA CONTAINED HEREIN, IN WHO' +
                'LE OR IN PART, IS STRICTLY PROHIBITED EXCEPT  WITH THE PRIOR WRITTEN PERMISSION OF CFRA.',
        ],
    ]);

    const nameCol = worksheet.getColumn('A');
    nameCol.width = 115;
    nameCol.alignment = { wrapText: true };
    return workbook;
};

function getTableColumns(columns: Map<string, ValueTypes>) {
    let columnsToExport: any[] = [];
    columns.forEach((type: ValueTypes, column: string) => {
        columnsToExport.push({ name: column, filterButton: false });
    });
    return columnsToExport;
}

function getBlankRowNumber(ws: Worksheet, marginBottomRows: number = 3): number {
    return ws.rowCount + marginBottomRows;
}

function setCustomFields(ws: Worksheet, customFields: ExcelCustomFields) {
    let firstBlankRow: number = getBlankRowNumber(ws);
    for (var i = 0; i < customFields.data.length; i++) {
        let row = ws.getRow(firstBlankRow);
        for (var j = 0; j < customFields.data[i].length; j++) {
            let cell = row.getCell(j + 1);
            cell.value = customFields.data[i][j];
            if (customFields.fieldStyle && customFields.fieldStyle[i][j]) {
                if (customFields.fieldStyle[i][j].font) cell.font = customFields.fieldStyle[i][j].font!;
                if (customFields.fieldStyle[i][j].alignment) cell.alignment = customFields.fieldStyle[i][j].alignment!;
            }
        }
        firstBlankRow++;
    }
}

function getHeaderAddresses(ws: Worksheet, tableRowRef: number) {
    let headerAddresses: { [columnName: string]: string } = {};
    const row = ws.getRow(tableRowRef);

    row.eachCell({ includeEmpty: true }, function (cell) {
        headerAddresses[String(cell.value)] = cell.address.replace(/[^a-zA-Z]+/g, ''); // ignore row number
    });

    return headerAddresses;
}

function setWidth(
    column: Column,
    numberOfColumns: number,
    style?: ColumStyle,
    maxWidth: number = 155,
    autoSizeColumns: boolean = false,
) {
    if (style) {
        column.width = maxWidth * getPercentageValue(style.width);
    } else if (!autoSizeColumns) {
        column.width = maxWidth / numberOfColumns;
    }
}

function setAlignment(column: Column, type: ValueTypes) {
    switch (type) {
        case ValueTypes.Numeral:
        case ValueTypes.Integer:
        case ValueTypes.Percentage:
        case ValueTypes.ConvertedPercentage: {
            column.alignment = { horizontal: 'right' };
            break;
        }
        default: {
            column.alignment = { horizontal: 'left' };
            break;
        }
    }
}

function setType(column: Column, type: ValueTypes) {
    switch (type) {
        case ValueTypes.Integer: {
            column.numFmt = '0';
            break;
        }
        case ValueTypes.Numeral: {
            column.numFmt = '0.00';
            break;
        }
        case ValueTypes.Percentage: {
            column.numFmt = '0.00%';
            break;
        }
        case ValueTypes.ConvertedPercentage: {
            column.numFmt = '#,#0.00"%"';
            break;
        }
        default: {
            break;
        }
    }
}

function formatColumnStyle(
    ws: Worksheet,
    headerAddresses: { [columnName: string]: string },
    columns: Map<string, ValueTypes>,
    columnStyles?: { [id: string]: ColumStyle },
    autoSizeColumns?: boolean,
) {
    let numberOfColumns: number = Object.keys(headerAddresses).length;
    Object.entries(headerAddresses).forEach(([columnName, addr]) => {
        const column = ws.getColumn(addr);
        const columnType = columns.get(columnName);

        setWidth(column, numberOfColumns, columnStyles?.[columnName], undefined, autoSizeColumns);
        if (columnType) {
            setAlignment(column, columnType);
            setType(column, columnType);
        }
    });
}

function   setDataTable(ws: Worksheet, table: ExcelTable, colRef: string = 'A', header: boolean = true): TableProps {
    const tableRowRef: number = getBlankRowNumber(ws);
    // create table
    ws.addTable({
        name: 'Table',
        ref: `${colRef}${tableRowRef}`,
        headerRow: header, // [BUG] can't be false https://github.com/exceljs/exceljs/issues/1615
        totalsRow: false,
        style: {
            theme: 'TableStyleLight1', // [BUG] can't be null
            showRowStripes: false,
        },
        columns: getTableColumns(table.columns), // add column properties
        rows: table.data,
    });

    let props: TableProps = {
        colRef: 'A',
        tableStart: header ? tableRowRef + 1 : tableRowRef,
        tableEnd: ws.rowCount,
        headerAddresses: header ? getHeaderAddresses(ws, tableRowRef) : {},
    };

    return props;
}

function notEmptyArray(element: any[]) {
    return element.length > 0;
}

function setMetadata(ws: Worksheet, metadata: ExcelMetadata) {
    const metadataView: any[][] = [
        [metadata.cardName],
        metadata.etfName ? ['ETF Name:', metadata.etfName] : [],
        metadata.ticker ? ['Ticker:', metadata.ticker] : [],
        ['Download Date:', todayDateStringUS()],
        metadata.asOfDate ? ['Data as of:', metadata.asOfDate] : [],
    ].filter(notEmptyArray);

    const rows = ws.addRows(metadataView);
    rows[0].getCell(1).style = { font: { bold: true } };

}

function autoSizeColumnWidths(worksheet: Worksheet) {
    worksheet.columns.forEach((column) => {
        const lengths = column.values?.map((v) => (v ? v.toString().length : 0));

        if (lengths) {
            const maxLength = Math.max(...lengths.filter((v) => typeof v === 'number'));
            column.width = Math.max(maxLength, 8); // 8 is the min size. feel free to parameterize this in the future
        }
    });
}

export const downloadXLSX = (workbook: Workbook, fileName: string) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    });
};

export const getExportExcelFileName = (metadata: ExcelMetadata, fileName?: string) => {
    return fileName ? fileName : `${metadata.cardName}${metadata.ticker ? ` ${metadata.ticker}` : ''}`;
};

export const exportExcel = async (
    table: ExcelTable,
    metadata: ExcelMetadata,
    customFields?: ExcelCustomFields,
    autoSizeColumns?: boolean,
    sheetName?: string,
    fileName?: string,
) => {
    // Since the Library size is so large, we should only download when requested
    // eslint-disable-next-line
    import('exceljs').then(async (ExcelJS) => {
        // Create workbook
        const workbook = new ExcelJS.Workbook();
        // Add Data Worksheet
        const worksheet = workbook.addWorksheet(sheetName ? sheetName : metadata.cardName);

        const logoID = await fetch(logoFile)
            .then((result: Response) => result.arrayBuffer())
            .then((buffer) => workbook.addImage({ buffer: buffer, extension: 'png' }));

        worksheet.addImage(logoID, {
            tl: { col: 0, row: 0 },
            ext: { width: 130, height: 30 },
        });

        worksheet.mergeCells('A1', 'A2');
        // set metadata information
        setMetadata(worksheet, metadata);
        // create table with data
        let tableProps: TableProps = setDataTable(worksheet, table);
        // use Autowidth
        if (autoSizeColumns) {
            autoSizeColumnWidths(worksheet);
        }

        // styles for table
        formatColumnStyle(worksheet, tableProps.headerAddresses, table.columns, table.columnStyles, autoSizeColumns);

        // custom logic here
        if (customFields) setCustomFields(worksheet, customFields);
        // add disclaimer sheet
        setDisclaimer(workbook);
        // Export to Excel
        downloadXLSX(workbook, getExportExcelFileName(metadata, fileName));
    });
};

function getFirstOrSecondLevelProperty(fieldArray: Array<any>, values: Array<any>) {
    return fieldArray.length === 1 ? values[fieldArray[0]] : values[fieldArray[0]][fieldArray[1]];
}

export type AgGridExportProps = {
    fileName?: string;
    sheetName?: string;
    metadata: ExcelMetadata;
    asOfDateField?: string;
    useMaxAsOfDate?: boolean;
    autoSizeColumns?: boolean;
};

export function exportAgGrid(
    exportRef: React.RefObject<AgGridReact<colDefType>> | undefined,
    agGridProps: AgGridExportProps,
) {
    const rowData: any = exportRef?.current?.props?.rowData;
    const columns: any = exportRef?.current?.props?.columnDefs;

    // If we have an asOfDateField, figure out what it should be
    if (agGridProps.asOfDateField) {
        const fieldArray = agGridProps.asOfDateField.split('.');
        const maxDateRow = agGridProps.useMaxAsOfDate
            ? maxBy(rowData, function (row: any) {
                  return getFirstOrSecondLevelProperty(fieldArray, row);
              })
            : rowData[0];
        const maxDateString = getFirstOrSecondLevelProperty(fieldArray, maxDateRow);
        const maxDateMoment = getMomentObjectFrom(maxDateString).format('l');

        agGridProps.metadata.asOfDate = maxDateMoment;
    }

    // Map AG Grid row to ExcelTable rows
    let excelTableData: any[][] = [];
    rowData.forEach((row: any) => {
        const newRow: any = [];
        columns.forEach((column: any) => {
            newRow.push(get(row, column.field));
        });
        excelTableData.push(newRow);
    });

    // Map AG Grid Columns to ExcelTable columns
    let excelTableColumns: Map<string, ValueTypes> = new Map<string, ValueTypes>();
    columns.forEach((column: any) => {
        excelTableColumns.set(column.headerName, column.valueType);
    });

    // Create Exceltable for Export
    const excelTable: ExcelTable = {
        data: excelTableData,
        columns: excelTableColumns,
        columnStyles: {},
    };

    exportExcel(
        excelTable,
        agGridProps.metadata,
        undefined,
        agGridProps.autoSizeColumns,
        agGridProps.sheetName,
        agGridProps.fileName,
    );
}
