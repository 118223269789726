import { Chart } from 'components/Chart/Chart';
import { ETFButtonsPannelButtonProps } from 'components/layout/ETFButtonsPannel';
import Highcharts, { Axis, AxisTickPositionsArray } from 'highcharts/highstock';
import moment, { Moment } from 'moment';
import { DateRanges, Frequencies, getFrequency, getNextMonday, isWeekEnd } from 'utils';
import { ValueTypes } from 'utils/valuesFormatter';
import { CustomExportsProps } from './ExportMenus';
import { FlowChartOptions } from './Options';


type FlowsChartProps = {
    customOptions?: Highcharts.Options, // options to add to/override default options
    periodSelected: DateRanges,
    tooltipFormatter: (this: Highcharts.TooltipFormatterContextObject) => string,
    xAxisDates: Array<Moment>,
    title: string,
    exportFileName: string,
    columnHeaderFormatter: (item: any) => string,
    setPeriodSelected: (value: DateRanges) => void,
    dateRangesWithDisabledButton: Set<DateRanges>,
};

function getButtonsPannelConfig(
    setPeriodSelected: (value: DateRanges) => void,
    dateRangesWithDisabledButton: Set<DateRanges>,
    defaultPeriodSelected?: DateRanges
): Array<ETFButtonsPannelButtonProps> {
    const dateRangeButtonsCallbackFunction = (selectedPeriod: DateRanges | string) => {
        setPeriodSelected(selectedPeriod as DateRanges);
    };

    return Object.values(DateRanges).map((dateRange) => {
        return {
            name: dateRange,
            callback: dateRangeButtonsCallbackFunction,
            disabled: dateRangesWithDisabledButton.has(dateRange),
            isDefault: dateRange === defaultPeriodSelected
        };
    });
}

export function formatFlowsNumber({ value, useNumberFormat = true, skipSpaceBetweenDollarAndNumber = false }: { value: number, useNumberFormat?: boolean, skipSpaceBetweenDollarAndNumber?: boolean }) {
    let result: string;
    let getStringValue = (value: number) => useNumberFormat ? Highcharts.numberFormat(value, 2, '.', ',') : String(value);
    if (Math.abs(value) < 1_000) result = getStringValue(value);
    else if (Math.abs(value) < 1_000_000) result = `${getStringValue(value / 1_000)} K`;
    else result = `${getStringValue(value / 1_000_000)} M`;
    return `$${skipSpaceBetweenDollarAndNumber ? '' : ' '}${result}`
};

function yAxisLabelsFormatter(this: Highcharts.AxisLabelsFormatterContextObject) {
    return formatFlowsNumber({ value: this.value as number, useNumberFormat: false });
};

function getXAxisLebelsFormat(periodSelected: DateRanges) {
    const getFormatString = (format: string): string => `{value:${format}}`;
    if ([DateRanges.SixMonth, DateRanges.OneYear, DateRanges.ThreeYears].includes(periodSelected)) return getFormatString('%b %Y');
    if (periodSelected === DateRanges.FiveYears) return getFormatString('%Y');
    return getFormatString('%d %b');
};

const getXAxisTickPositioner = (periodSelected: DateRanges, xAxisDates: Array<Moment>) => function (this: Axis): AxisTickPositionsArray {
    const enum Periods {
        Days = 'days',
        Weeks = 'weeks',
        Month = 'month',
        Years = 'years'
    }

    let positions: Array<Moment> = [];
    let peroidName: moment.unitOfTime.DurationConstructor = Periods.Days; // Daily for OneWeek
    let numberOfperiods = 1;

    if (periodSelected === DateRanges.OneMonth || periodSelected === DateRanges.ThreeMonth) { // Weekly for OneMonth, Bi-weekly for ThreeMonth
        peroidName = Periods.Weeks;
        numberOfperiods = periodSelected === DateRanges.OneMonth ? 1 : 2;
    }
    if (periodSelected === DateRanges.SixMonth || periodSelected === DateRanges.OneYear) { // monthly for SixMonth, Quarterly for OneYear and ThreeYears
        peroidName = Periods.Month;
        numberOfperiods = periodSelected === DateRanges.SixMonth ? 1 : 3;
    }
    if (periodSelected === DateRanges.ThreeYears) {
        peroidName = Periods.Month;
        numberOfperiods = 6;
    }
    if (periodSelected === DateRanges.FiveYears) { // Annually for FiveYears
        peroidName = Periods.Years;
        numberOfperiods = 1;
    }
    const minValue = moment.min(xAxisDates).clone();
    const maxValue = moment.max(xAxisDates).clone();

    const periodsToDays: Record<Periods, number> = {
        [Periods.Days]: 1,
        [Periods.Weeks]: 7,
        [Periods.Month]: 30,
        [Periods.Years]: 365
    }

    const getPercentPertOfPeriodInDays = (numberOfperiods: number, peroidName: Periods, percent: number = 0.8): [number, Periods] => { // by default returns 80% of period in days
        if (peroidName === Periods.Days && numberOfperiods === 1) return [numberOfperiods, peroidName];
        return [Math.round(numberOfperiods * periodsToDays[peroidName] * percent), Periods.Days];
    }

    const isSameOrBefore = (startDate: Moment, endDate: Moment): boolean => startDate.clone().add(...getPercentPertOfPeriodInDays(numberOfperiods, peroidName as Periods)).isSameOrBefore(endDate)

    for (let i = minValue; isSameOrBefore(positions.length > 0 ? positions[positions.length - 1] : i, maxValue); i.add(numberOfperiods, peroidName)) {
        // handle cases when tick is weekend and show daily to avoid gaps on the chart
        if (isWeekEnd(i)) {
            if (getFrequency(periodSelected) === Frequencies.Daily) continue; // skip tick if weekend for daily
            else positions.push(getNextMonday(i)); // for not daily if weekend replace tick with closest next monday, need to not have empty spaces when tick === weekend, because for weekends we have no data
            continue;
        }

        const IContainsInsideXAxisDates = () => {
            let result = false;
            xAxisDates.forEach(value => {
                if (value.isSame(i)) result = true;
            })
            return result;
        };

        // handle cases when tick is weekday and show daily, but have no responcce for this data at all (can be if there was Bank Holiday) to avoid gaps on the chart
        if (periodSelected === DateRanges.OneWeek && !IContainsInsideXAxisDates() && i.isSameOrBefore(maxValue)) continue;

        positions.push(i.clone());
    }

    return positions.map(value => value.toDate().getTime());
};

export function getDateForTooltipFormatter(_this: Highcharts.TooltipFormatterContextObject, periodSelected: DateRanges): string {
    const MOMENT_FORMAT = { DAILY: 'MM/DD/YYYY', WEEKLY: 'dddd MM/DD/YYYY', MONTHLY: '[Month of] MMMM YYYY' }
    const selectedPeriodFrequency = getFrequency(periodSelected);

    if (selectedPeriodFrequency === Frequencies.Daily) {
        return moment.utc(_this.x).format(MOMENT_FORMAT.DAILY);
    } else if (selectedPeriodFrequency === Frequencies.Weekly) {
        const formatToDayOfWeekAndDate = (date: Moment): string => `${date.format(MOMENT_FORMAT.WEEKLY)}`;
        const startDate = moment.utc(_this.x);
        const endDate = moment.utc(_this.x).add(4, 'days');

        return `${formatToDayOfWeekAndDate(startDate)} - ${formatToDayOfWeekAndDate(endDate)}`;
    }
    return moment.utc(_this.x).format(MOMENT_FORMAT.MONTHLY);
};

export function FlowsChart({ customOptions, periodSelected, tooltipFormatter, xAxisDates, title, exportFileName, columnHeaderFormatter, setPeriodSelected, dateRangesWithDisabledButton }: FlowsChartProps) {
    const options: Highcharts.Options = {
        chart: {
            marginBottom: undefined,
            marginTop: 40,
            spacingLeft: 0,
            spacingRight: 20,
            spacingTop: 10,
            type: 'column'
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['downloadCSV', 'downloadJPEG']
                }
            },
            chartOptions: {
                chart: {
                    spacingBottom: 5
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: false
                        }
                    },
                    series: {
                        dataLabels: {
                            allowOverlap: true,
                            crop: false,
                            enabled: false,
                            padding: 0,
                            rotation: -90,
                            y: 20
                        }
                    }
                },
                xAxis: {
                    labels: {
                        style: {
                            fontSize: periodSelected === DateRanges.ThreeYears ? '8px' : undefined // make font smaller because big amount of values for ThreeYears

                        }
                    }
                }
            },
            csv: {
                columnHeaderFormatter: columnHeaderFormatter,
                dateFormat: '%m/%d/%Y'
            },
            enabled: false,
            fallbackToExportServer: false,
            filename: exportFileName
        },
        plotOptions: {
            column: {
                minPointLength: 0
            }
        },
        title: {
            align: 'left',
            margin: 40,
            style: {
                color: '#222222',
                fontFamily: 'Roboto,sans-serif',
                fontSize: '24px',
                fontWeight: '500'
            },
            text: undefined
        },
        tooltip: {
            formatter: tooltipFormatter,
            shared: true
        },
        xAxis: {
            categories: undefined,
            labels: {
                align: undefined,
                enabled: true,
                format: getXAxisLebelsFormat(periodSelected),
                rotation: undefined,
                style: {
                    color: '#666666',
                    fontFamily: undefined,
                    fontSize: '14px',
                    fontWeight: undefined,
                    width: undefined
                }
            },
            lineWidth: 0,
            offset: 0,
            ordinal: getFrequency(periodSelected) === Frequencies.Daily,
            startOnTick: true,
            tickPositioner: getXAxisTickPositioner(periodSelected, xAxisDates),
            title: {
                text: undefined
            },
            type: 'datetime'
        },
        yAxis: {
            labels: {
                format: '{value} %',
                formatter: yAxisLabelsFormatter,
                overflow: 'allow',
                style: {
                    fontSize: '12px'
                }
            },
            title: {
                text: undefined
            }
        }
    };

    return (<Chart
        title={title}
        options={Highcharts.merge(options, customOptions)}
        ETFButtonsPannelButtonsProps={getButtonsPannelConfig(setPeriodSelected, dateRangesWithDisabledButton, periodSelected)}
    />);
};

export function StyledFlowsChart({
    series,
    title,
    subTitle,
    ticks,
    tooltipFormatter,
    periodSelected,
    xAxisDates,
    exportFileName,
    columnHeaderFormatter,
    setPeriodSelected,
    dateRangesWithDisabledButton,
    enableLegend = false,
    plotOptionsAnimations,
    customExports,
    exports
}: {
    series: any[];
    title: string;
    subTitle: string;
    ticks: {
        max: number;
        min: number;
        tickerInterval: number;
    };
    periodSelected: DateRanges;
    tooltipFormatter: (this: Highcharts.TooltipFormatterContextObject) => string;
    xAxisDates: Array<Moment>;
    exportFileName: string;
    columnHeaderFormatter: (item: any) => string;
    setPeriodSelected: (value: DateRanges) => void;
    dateRangesWithDisabledButton: Set<DateRanges>;
    enableLegend?: boolean;
    plotOptionsAnimations?: boolean;
    customExports?: CustomExportsProps;
    exports?: {
        asOfDate: string;
        etfName?: string;
        columns: Map<string, ValueTypes>;
        ticker?: string;
    };
}) {
    const options = FlowChartOptions({
        series,
        tooltipFormatter,
        exportFileName,
        ticks,
        columnHeaderFormatter,
        ...{ xAxisLabelFormat: getXAxisLebelsFormat(periodSelected) },
        ...{ xAxisOrdinal: getFrequency(periodSelected) === Frequencies.Daily },
        ...{ xAxisTickPositioner: getXAxisTickPositioner(periodSelected, xAxisDates) },
        yAxisLabelsFormatter,
        enableLegend,
        analyticsLegendCallback: (action: string) => {
            globalThis.analytics?.registerAction?.({
                action: action,
                cardName: title,
                dateRange: periodSelected,
            });
        },
        plotOptionsAnimations
    });

    return (
        <Chart
            title={title}
            subTitle={subTitle}
            options={options}
            ETFButtonsPannelButtonsProps={getButtonsPannelConfig(
                (value: DateRanges) => {
                    globalThis.analytics?.registerAction?.({
                        action: `date range selection : ${value}`,
                        cardName: title,
                        dateRange: periodSelected,
                    });
                    setPeriodSelected(value);
                },
                dateRangesWithDisabledButton,
                periodSelected,
            )}
            exportFileName={exportFileName}
            customExports={customExports}
            exports={exports}
        />
    );
};