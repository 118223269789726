import { createTheme, Grid } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import Tooltip from 'components/dataDisplay/Tooltip';
import { ETFLinkButton, ETFRoundedButton } from 'components/ETFButton';
import { ETFCard } from 'components/layout';
import { ETFTwoColumnGrid, Item } from 'components/layout/ETFTwoColumnGrid';
import { tooltipTheme } from 'components/themes/theme';
import React, { useState } from 'react';
import {
    assetClasses,
    DataPointsDisplayNames,
    formatValue,
    FormatValueParams,
    getDataPointDisplayNameToFieldName,
    getDataPointsDisplayNames,
    getDataPointsDisplayNameToFormattingType,
} from 'utils';
import { EtfDetailsData } from '../types/research';

export default function FundProfile({ etfDetailsData }: { etfDetailsData: EtfDetailsData }) {
    const [isExpanded, setExpanded] = useState(false);
    const theme = createTheme(tooltipTheme, {
        components: {
            MuiTooltip: {
                defaultProps: {
                    placement: 'top-start',
                },
            },
        },
    });

    // get display names list for all data points
    const DataPointsDisplayNames = getDataPointsDisplayNames();
    // get matching between display names and object property name
    const dataPointDisplayNameToFieldName = getDataPointDisplayNameToFieldName();
    // create list of data points divided by sections
    const orderedDataPoints: DataPointsDisplayNames[] = [
        DataPointsDisplayNames.AssetClass,
        DataPointsDisplayNames.CFRACategoryOne,
        DataPointsDisplayNames.CFRACategoryTwo,
        DataPointsDisplayNames.AssetType,
        DataPointsDisplayNames.MultiAssetType,
        DataPointsDisplayNames.CommodityType,
        DataPointsDisplayNames.CurrencyPair,
        DataPointsDisplayNames.BondType,
        DataPointsDisplayNames.MarketCapRange,
        DataPointsDisplayNames.GrowthValueTilt,
        DataPointsDisplayNames.IndexWeightedScheme,
        DataPointsDisplayNames.SmartTraditionalBeta,
        DataPointsDisplayNames.CreditQuality,
        DataPointsDisplayNames.AverageMaturity,
        DataPointsDisplayNames.RegionOfExposure,
        DataPointsDisplayNames.RegionOfListing,
        DataPointsDisplayNames.Exchange,
        DataPointsDisplayNames.NumberOfHoldings,
        DataPointsDisplayNames.ExpenseRatio,
        DataPointsDisplayNames.InceptionDate,
        DataPointsDisplayNames.LegalStructure,
        DataPointsDisplayNames.Sponsor,
        DataPointsDisplayNames.FundFamily,
        DataPointsDisplayNames.UnderlyingIndex,
        DataPointsDisplayNames.ETFWebsite,
    ];
    // create matching between asset class and list of data point for it
    const assetClassToDataPoint: { [id: string]: Array<string> } = {
        [assetClasses.Bonds]: [
            DataPointsDisplayNames.BondType,
            DataPointsDisplayNames.CreditQuality,
            DataPointsDisplayNames.AverageMaturity,
        ],
        [assetClasses.EquitiesStocks]: [DataPointsDisplayNames.MarketCapRange, DataPointsDisplayNames.GrowthValueTilt],
        [assetClasses.TargetDateMultiAsset]: [DataPointsDisplayNames.MultiAssetType],
        [assetClasses.CommoditiesAndMetals]: [DataPointsDisplayNames.CommodityType],
        [assetClasses.Currency]: [DataPointsDisplayNames.CurrencyPair],
        [assetClasses.OtherAssetTypes]: [DataPointsDisplayNames.AssetType],
    };
    // get matching between display names and value formatting type
    const dataPointsDisplayNameToFormattingType = getDataPointsDisplayNameToFormattingType();
    // create a list of data points that have specification for the exact asset class
    let spesificDataPonts: Array<string> = [];
    Object.keys(assetClassToDataPoint).forEach(function (key) {
        spesificDataPonts = spesificDataPonts.concat(assetClassToDataPoint[key]);
    });
    // create instance of class FormatValueParams
    let formatValueParams = new FormatValueParams({
        source: etfDetailsData,
        dataPointDisplayNameToFieldName: dataPointDisplayNameToFieldName,
        dataPointsDisplayNameToFormattingType: dataPointsDisplayNameToFormattingType,
    });

    // get formatted value for asset class
    const assetClass = formatValue(formatValueParams.create({ key: DataPointsDisplayNames.AssetClass }));

    // fill renderedItemValue with each column item
    const renderedItemValue: React.ReactNode[] = [];
    orderedDataPoints.forEach((itemName, index) => {
        const StyledItem = styled(Item)(() => ({
            marginTop: index === 0 ? '0px' : '',
        }));

        // don't show items if they not applicable to this asset class
        if (
            assetClassToDataPoint.hasOwnProperty(assetClass) &&
            !assetClassToDataPoint[assetClass].includes(itemName) &&
            spesificDataPonts.includes(itemName)
        )
            return;

        if (renderedItemValue.length >= 10 * 2 && !isExpanded) return; // show only 10 data items if card is semi-colapsed

        // add data point name
        renderedItemValue.push(
            <Tooltip title={itemName}>
                <StyledItem>{itemName}</StyledItem>
            </Tooltip>,
        );

        // get formatted data point value
        let formatedValue = formatValue(formatValueParams.create({ key: itemName }));
        // for ETFWebsite data point add button
        if (itemName === DataPointsDisplayNames.ETFWebsite && formatedValue !== '-') {
            renderedItemValue.push(
                <Grid sx={{ verticalAlign: 'bottom' }}>
                    <ETFLinkButton href={formatedValue.toString()} target='_blank' text='Click here' />
                </Grid>,
            );
        } else {
            // for other data points add data point value
            renderedItemValue.push(
                <Tooltip title={formatedValue}>
                    <StyledItem>{formatedValue}</StyledItem>
                </Tooltip>,
            );
        }
    });

    const getButtonText = () => (isExpanded ? 'View Less' : 'View More');
    const cardName = 'Overview';

    return (
        <ThemeProvider theme={theme}>
            <ETFCard cardLabel={cardName}>
                <ETFTwoColumnGrid>{React.Children.toArray(renderedItemValue)}</ETFTwoColumnGrid>
                <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'center', paddingTop: '28px', height: '68px' }}>
                    <ETFRoundedButton
                        text={getButtonText()}
                        callback={() => {
                            globalThis.analytics?.registerAction?.({
                                action: `click on ${getButtonText().toLowerCase()}`,
                                cardName: cardName,
                            });
                            setExpanded(!isExpanded);
                        }}
                    />
                </Grid>
            </ETFCard>
        </ThemeProvider>
    );
}
