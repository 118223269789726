import { ETFSearchByParams, getRequestQuery, prefetchQuerry, UseData } from 'utils';
import {
    EtfData,
    ETFFlowsToAssetThemesAndFactorsData,
    ETFPerformanceThemesAndFactorsData,
    KeyAssetClassesData,
} from '../types/research';

export function getKeyAssetClassesData({ cfraIds, tickerExchanges }: ETFSearchByParams) {
    return UseData<Array<KeyAssetClassesData>>({
        requestQuerry: getRequestQuery({ cfraIds, tickerExchanges }, 'performance/'),
        queryKey: ['getKeyAssetClassesData', `${JSON.stringify(cfraIds)}`, `${JSON.stringify(tickerExchanges)}`],
    });
}

export function getAggregationFlows({ top, orderBy, sortDirection, aggregateBy, usePrefetchQuery }: ETFSearchByParams) {
    const requestQuerry = getRequestQuery({ top, orderBy, sortDirection, aggregateBy }, 'aggregation/flows/');
    const queryKey = ['getAggregationFlows', top, orderBy, sortDirection, aggregateBy];

    if (usePrefetchQuery) {
        return prefetchQuerry({ requestQuerry, queryKey });
    }

    return UseData<Array<ETFFlowsToAssetThemesAndFactorsData>>({
        requestQuerry,
        queryKey,
    });
}

export function getAggregationPerformance({
    top,
    orderBy,
    sortDirection,
    aggregateBy,
    usePrefetchQuery,
}: ETFSearchByParams) {
    const requestQuerry = getRequestQuery({ top, orderBy, sortDirection, aggregateBy }, 'aggregation/performance/');
    const queryKey = ['getAggregationPerformance', top, sortDirection, orderBy, aggregateBy];

    if (usePrefetchQuery) {
        return prefetchQuerry({ requestQuerry, queryKey });
    }

    return UseData<Array<ETFPerformanceThemesAndFactorsData>>({
        requestQuerry,
        queryKey,
    });
}

export function getEtfs({ fields, filterBy, orderBy, sortDirection }: ETFSearchByParams) {
    return UseData<Array<EtfData>>({
        requestQuerry: getRequestQuery({ fields, filterBy, orderBy, sortDirection }, 'etfs/'),
        queryKey: ['getEtfs', fields, filterBy, orderBy, sortDirection],
    });
}
