import Box from '@mui/material/Box';
import { Page } from 'analytics/Page';
import Skeleton from 'components/layout/Skeleton';
import { useParams } from 'react-router-dom';
import { getEtfDataOverview } from '../api/etfDetailsData';
import ETFDetailTabs from '../components/ETFDetailsTabs';
import ETFDetailsData from '../components/EtfDetailsData';
import { ETFDetailsParams } from '../types/research';

export function ETF() {
    const { ticker, exchange, cfraId } = useParams<keyof ETFDetailsParams>() as ETFDetailsParams;

    // Why we send parameters as dictionary?
    const latestUpdatesQuery = getEtfDataOverview({ ticker: ticker, exchange: exchange, cfraId: cfraId });

    // show loading if data still loading
    if (latestUpdatesQuery.isLoading) {
        return (
            <Box sx={{ minHeight: 1000 }}>
                <Skeleton />
            </Box>
        );
    }

    // Show Nothing if no data
    if (!latestUpdatesQuery || !latestUpdatesQuery.data) return null;

    const companyData = {
        ticker: latestUpdatesQuery.data.ticker,
        exchange: latestUpdatesQuery.data.exchange_code,
        cfraId: latestUpdatesQuery.data.cfra_id,
        composite_name: latestUpdatesQuery.data.composite_name,
    };

    return (
        <Page
            name='etf details'
            ticker={companyData.ticker}
            etfName={latestUpdatesQuery.data.composite_name}
            etfAssetClass={latestUpdatesQuery.data.asset_class}
            etfExchange={companyData.exchange}
            cfraId={latestUpdatesQuery.data.cfra_id}
            clearPreviousSection={false} // need to keep page section
        >
            <ETFDetailsData etfDetailsData={latestUpdatesQuery.data} />
            <ETFDetailTabs latestUpdatesQuery={latestUpdatesQuery.data} companyData={companyData} />
        </Page>
    );
}
export default ETF;
