import { getMinAndMaxTick, getTickIntervalFrom } from 'components/Chart/Chart';
import {
    addAsOfDateToHighchart,
    addLogoToHighchart,
    exportChartAsImgWithFonts,
    getExportDefaultChartOptions,
} from 'components/Chart/Export';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import { StyledFlowsChart, formatFlowsNumber, getDateForTooltipFormatter } from 'components/Chart/Flows';
import { getMarginOptions, getSpacingOptions } from 'components/Chart/Options';
import { DateValuePair, NumberOrNull } from 'components/Chart/types';
import { DataAsOfDate, ETFCard, ETFEmptyCard } from 'components/layout';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { Moment } from 'moment';
import { DateRanges, getMomentObjectFrom } from 'utils';
import { ValueTypes } from 'utils/valuesFormatter';
import { ETFDataAllRangesQueryResult, ETFDataFlows, ETFFlowsProps } from '../types/research';
import {
    determineDateRangeToShowByDefault,
    extractETFDataForAllRanges,
    getChartDataAllRanges,
    getDateRangesWithDisabledButton,
    sortByPeriodAsc,
} from './ETFFlows';
import { useLocalStorage } from 'components/hooks/useLocalStorage';

type ETFFlowsVSMarketMovementChartData = {
    xAxisDates: Array<Moment>;
    categoriesData: Array<NumberOrNull>;
    series: Array<{
        data: Array<DateValuePair>;
        type: 'column' | 'spline';
        name?: string;
    }>;
};

const localStorageID = 'etf-flows-vs-market-movement-chart-';

function getChartDataFor(ETFFlowsData: Array<ETFDataFlows>) {
    const result: ETFFlowsVSMarketMovementChartData = {
        xAxisDates: [],
        categoriesData: [],
        series: [
            {
                data: [],
                type: 'column',
                name: 'Market Movement',
            },
            {
                data: [],
                type: 'column',
                name: 'Flows',
            },
            {
                data: [],
                type: 'spline',
                name: 'Asset Change',
            },
        ],
    };

    ETFFlowsData.forEach((value) => {
        result.xAxisDates.push(getMomentObjectFrom(value.period_of));
        result.categoriesData = result.categoriesData.concat([
            value.net_flows,
            value.asset_change,
            value.market_value_change,
        ]);
        const getDateValuePair = (date: string, value: number | null) =>
            [getMomentObjectFrom(date).toDate().getTime(), value] as DateValuePair;
        result.series[0].data.push(getDateValuePair(value.period_of, value.market_value_change));
        result.series[1].data.push(getDateValuePair(value.period_of, value.net_flows));
        result.series[2].data.push(
            getDateValuePair(value.period_of, value.asset_change === null ? 0 : value.asset_change),
        );
    });

    return result;
}

export default function ETFFlowsVSMarketMovement({ companyData, cfraId, componentRendered }: ETFFlowsProps) {
    const [periodSelected, setPeriodSelected] = useLocalStorage<DateRanges>(
        localStorageID + 'selected-date-range',
        DateRanges.ThreeMonth,
    );
    const etfDataAllRangesQueryResult: ETFDataAllRangesQueryResult = getChartDataAllRanges(cfraId);

    // create function for setting columns names in csv export
    const columnHeaderFormatter = function (item: any) {
        if (item instanceof Highcharts.Axis && item.isXAxis) {
            return 'Date';
        }
        return periodSelected + ' ' + item.name;
    };

    // is data for any of ranges still loading, show loading element
    if (
        Object.values(etfDataAllRangesQueryResult)
            .map((element) => element.isLoading)
            .some((x) => x)
    ) {
        return <ETFCard isLoading={true} />;
    }

    const chartTitle = 'Change in Assets: New Flows Vs. NAV';
    const chartDescription =
        'See how an ETFs total assets change over time as determined by both its net flows and changes in its net asset value (NAV)';

    // return EmptyCard if no data
    if (
        Object.values(etfDataAllRangesQueryResult)
            .map(
                (element) =>
                    Boolean(element) &&
                    Boolean(element.data) &&
                    Array.isArray(element.data) &&
                    element.data.length > 0 &&
                    element.data.some(
                        (obj) =>
                            Number.isFinite(obj.net_flows) ||
                            Number.isFinite(obj.asset_change) ||
                            Number.isFinite(obj.market_value_change),
                    ),
            )
            .every((x) => !x)
    ) {
        return <ETFEmptyCard cardLabel={chartTitle}></ETFEmptyCard>;
    }

    const etfDataAllRanges = extractETFDataForAllRanges(etfDataAllRangesQueryResult);

    const mapFunction = (element: ETFDataFlows) =>
        Number.isFinite(element.net_flows) ||
        Number.isFinite(element.asset_change) ||
        Number.isFinite(element.market_value_change);
    const defaultDateRange = determineDateRangeToShowByDefault(etfDataAllRanges, mapFunction);

    // show nothing if no data for all the ranges
    if (!defaultDateRange) return <ETFEmptyCard cardLabel={chartTitle}></ETFEmptyCard>;
    if (periodSelected === undefined) setPeriodSelected(defaultDateRange);
    // if no periodSelected set - show loading;
    if (periodSelected === undefined) return <ETFCard isLoading={true} />;

    const ETFFlowsData = sortByPeriodAsc(etfDataAllRanges[periodSelected]);
    const chartData = getChartDataFor(ETFFlowsData as Array<ETFDataFlows>) as ETFFlowsVSMarketMovementChartData;
    const xAxisDates = chartData.xAxisDates;
    const categoriesData = chartData.categoriesData;
    const series = chartData.series;
    const roundedTick = getTickIntervalFrom(categoriesData);

    const tooltipFormatter = function (this: any) {
        const date = getDateForTooltipFormatter(this, periodSelected);
        let tooltip: string = '';

        if (this.series.index === 0) {
            tooltip = `<tr><th><div>${date}</div></th></tr><tr><td>`;
        }
        return (
            tooltip +
            `<div class="leftPadding rightPadding"><span>${this.series.name}: ${formatFlowsNumber({
                value: this.y,
            })}</span></div>` +
            (this.series.index === 2 ? '</td></tr>' : '')
        );
    };

    const [minTick, maxTick]: [number, number] = getMinAndMaxTick(roundedTick, categoriesData, true);

    const exportFileName = `fund-flows-vs-market-movement-chart-${companyData.ticker}-${companyData.exchange}-${periodSelected}`;

    const asOfDate = [...Object.values(etfDataAllRanges).flatMap((x) => x.map((y) => y.as_of_date))]
        .sort()
        .reverse()[0];

    const columns: Map<string, ValueTypes> = new Map<string, ValueTypes>([
        ['Date', ValueTypes.Date],
        [periodSelected + ' Market Movement', ValueTypes.Currency],
        [periodSelected + ' Flows', ValueTypes.Currency],
        [periodSelected + ' Asset Change', ValueTypes.Currency],
    ]);

    const customExportsProps: CustomExportsProps = [
        {
            type: 'JPEG',
            callback: ({ chartRef }) =>
                exportETFFlowsVsMMJpegChart({
                    chartRef: chartRef.current,
                    title: chartTitle,
                    subTitle: chartDescription,
                    ticker: companyData.ticker,
                    asOfDate,
                    timeframe: periodSelected,
                }),
        },
    ];

    return (
        <ETFCard containerStyles={{ position: 'relative' }}>
            <StyledFlowsChart
                series={series}
                title={chartTitle}
                subTitle={chartDescription}
                ticks={{ min: minTick, max: maxTick, tickerInterval: roundedTick }}
                tooltipFormatter={tooltipFormatter}
                columnHeaderFormatter={columnHeaderFormatter}
                periodSelected={periodSelected}
                xAxisDates={xAxisDates}
                exportFileName={exportFileName}
                setPeriodSelected={setPeriodSelected}
                dateRangesWithDisabledButton={getDateRangesWithDisabledButton(etfDataAllRanges, mapFunction)}
                enableLegend={true}
                plotOptionsAnimations={componentRendered ? false : true}
                customExports={customExportsProps}
                exports={{
                    asOfDate,
                    columns,
                    etfName: companyData.composite_name,
                    ticker: companyData.ticker,
                }}
            />
            <DataAsOfDate date={asOfDate} />
        </ETFCard>
    );
}

async function exportETFFlowsVsMMJpegChart(props: {
    chartRef: HighchartsReact.RefObject;
    title: string;
    subTitle: string;
    ticker: string;
    asOfDate: string;
    timeframe: string;
}) {
    const { chartRef, title, subTitle, ticker, asOfDate, timeframe } = props;
    const chart: Highcharts.Chart = chartRef.chart;

    const defaultChartOptions: Highcharts.Options = getExportDefaultChartOptions({ title, subtitle: subTitle, ticker });

    const chartOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            ...getSpacingOptions([25, 45, 40, 40]),
            ...getMarginOptions([110, undefined, 110]),
            height: 600,
            width: 1000,
            events: {
                load: function (this: Highcharts.Chart) {
                    addLogoToHighchart(this);
                    addAsOfDateToHighchart(this, asOfDate);

                    if (timeframe) {
                        this.renderer
                            .text(`Timeframe: ${timeframe}`, this.chartWidth - 50, 40)
                            .attr({ align: 'right', zIndex: 99 })
                            .css({ color: '#57626a', fontSize: '14px' })
                            .add();
                    }
                },
            },
        },
    };

    exportChartAsImgWithFonts(chart, chartOptions);
}
