import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getDownloadAction } from 'analytics/utils';
import { TableExportMenus } from 'components/Chart/ExportMenus';
import { exportExcel, getExportExcelFileName } from 'components/excelExport/export';
import { ExcelMetadata, ExcelTable } from 'components/excelExport/type';
import { DataAsOfDate, ETFCard } from 'components/layout';
import { ItemHeader } from 'components/layout/ETFCard';
import Grid from 'components/layout/Grid';
import { ColumStyle } from 'components/layout/types';
import { tableTheme } from 'components/themes/theme';
import * as React from 'react';
import { asOfDateFormat } from 'utils';
import {
    assetClasses,
    DataPointsDisplayNames,
    formatValue,
    FormatValueParams,
    getDataPointDisplayNameToFieldName,
    getDataPointsDisplayNames,
    getDataPointsDisplayNameToFormattingType,
    isNumeric,
    SectionsNames,
    ValueTypes,
} from 'utils';
import { getEtfDataFlowsAndPerformance } from '../api/etfDetailsData';
import { EtfDetailsData } from '../types/research';

export default function ETFPerformance({ etfDetailsData, cfraId }: { etfDetailsData: EtfDetailsData; cfraId: string }) {
    // getting UseQueryResult object with data for creditExposure chart
    let etfDataFlowsAndPerformanceQuerryResult = getEtfDataFlowsAndPerformance({
        cfraId: cfraId,
    });
    // show card loading if data still loading
    if (etfDataFlowsAndPerformanceQuerryResult.isLoading) {
        return <ETFCard isLoading={etfDataFlowsAndPerformanceQuerryResult.isLoading} />;
    }
    // Show Nothing if no data
    if (!etfDataFlowsAndPerformanceQuerryResult.data) return null;
    // cut off UseQueryResult attributes, extract only etfDataFlowsAndPerformance data
    let etfDataFlowsAndPerformance = etfDataFlowsAndPerformanceQuerryResult.data.performance;
    // enum with table columns names
    const enum Columns {
        Period = 'Period',
        ReturnPercentages = 'Return %',
    }
    // get display names list for all data points
    const DataPointsDisplayNames = getDataPointsDisplayNames();
    // get matching between display names and object property name
    const dataPointDisplayNameToFieldName = getDataPointDisplayNameToFieldName(SectionsNames.ETFPerformance);
    // create matching between column name and column width
    const dataPointNameToCellStyle: { [id: string]: ColumStyle } = {
        [Columns.Period]: { width: '50%' },
        [Columns.ReturnPercentages]: { width: '50%' },
    };
    // get matching between display names and value formatting type
    const dataPointsDisplayNameToFormattingType = getDataPointsDisplayNameToFormattingType();
    // create list with the table columns order
    const columns: Array<Columns> = [Columns.Period, Columns.ReturnPercentages];

    const leftTableRows: React.ReactNode[] = [];
    const rightTableRows: React.ReactNode[] = [];

    const isLeftTable = (dataPointsDisplayName: DataPointsDisplayNames) =>
        [
            DataPointsDisplayNames.YTDReturn,
            DataPointsDisplayNames.OneMonthReturn,
            DataPointsDisplayNames.ThreeMonthReturn,
            DataPointsDisplayNames.OneYearReturn,
        ].includes(dataPointsDisplayName);

    const exportData: any[][] = [];
    // create list with order of table rows data points
    const dataPointsDisplayNames: DataPointsDisplayNames[] = [
        DataPointsDisplayNames.YTDReturn,
        DataPointsDisplayNames.OneMonthReturn,
        DataPointsDisplayNames.ThreeMonthReturn,
        DataPointsDisplayNames.OneYearReturn,
        DataPointsDisplayNames.ThreeYearReturn,
        DataPointsDisplayNames.FiveYearReturn,
        DataPointsDisplayNames.TenYearReturn,
        DataPointsDisplayNames.SinceInception,
    ];

    // get instance of class FormatValueParams
    let formatValueParams = new FormatValueParams({
        source: etfDataFlowsAndPerformance,
        dataPointDisplayNameToFieldName: dataPointDisplayNameToFieldName,
        dataPointsDisplayNameToFormattingType: dataPointsDisplayNameToFormattingType,
    });
    // fill tableRows and exportData objects with table rows filled with table cells with data
    dataPointsDisplayNames.forEach((rowKey: DataPointsDisplayNames, rowIndex: number) => {
        let tableCells: React.ReactNode[] = [];
        let exportCells: any = [];
        // fill tableCells object with cells with data
        columns.forEach((columnKey: string) => {
            let formatedValue: string | number = '';
            let formatValueParameters = formatValueParams.create({ key: rowKey });
            // if column name is Period than put in the cell data point name
            if (columnKey === Columns.Period) {
                formatedValue = rowKey;
                exportCells.push(rowKey);
            } else {
                // for not Period column put in the cell data point formatted value
                formatedValue = formatValue(formatValueParameters);
                exportCells.push(formatValueParameters.value);
            }

            if (columnKey === Columns.Period) {
                tableCells.push(<TableCell>{formatedValue}</TableCell>);
            } else {
                let color = undefined;
                let fotattedValue = String(formatedValue).replace('%', '');
                if (formatedValue !== '-' && isNumeric(fotattedValue)) {
                    color = Number(fotattedValue) > 0 ? '#5F9EA0' : '#E64A45';
                }
                tableCells.push(
                    <TableCell>
                        <Box color={color} sx={{ textAlign: 'right' }}>
                            {formatedValue}
                        </Box>
                    </TableCell>,
                );
            }
        });

        exportData.push(exportCells);

        const tableRow = <TableRow>{React.Children.toArray(tableCells)}</TableRow>;

        isLeftTable(rowKey) ? leftTableRows.push(tableRow) : rightTableRows.push(tableRow);
    });

    const excelTable: ExcelTable = {
        data: exportData,
        columns: new Map<string, ValueTypes>([
            ['Period', ValueTypes.Text],
            ['Return %', ValueTypes.Percentage]
        ]),
        columnStyles: dataPointNameToCellStyle,
    };

    const excelMetadata: ExcelMetadata = {
        cardName: SectionsNames.ETFPerformance,
        ticker: etfDetailsData.ticker,
        etfName: etfDetailsData.composite_name,
        asOfDate: asOfDateFormat(etfDataFlowsAndPerformance.as_of_date),
    };

    const getTable = (tableRows: React.ReactNode[]) => (
        <TableContainer component={Paper}>
            <Table aria-label='top holdings table'>
                <TableHead>
                    <TableRow>
                        {columns.map((column: Columns, index: number) => (
                            <TableCell
                                key={index}
                                sx={dataPointNameToCellStyle[column]}
                                align={column === Columns.ReturnPercentages ? 'right' : undefined}>
                                {column}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{React.Children.toArray(tableRows)}</TableBody>
            </Table>
        </TableContainer>
    );

    const theme = createTheme(tableTheme, {
        components: {
            MuiCustomBox: {
                styleOverrides: {
                    root: {
                        padding: '28px',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        paddingRight: '0px',
                        paddingLeft: '0px',
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:last-of-type td': {
                            borderBottom: '0px',
                        },
                    },
                },
            },
        },
    });

    const getRenderedTable = () => {
        const assetClass = etfDetailsData.asset_class;
        if (assetClass === assetClasses.EquitiesStocks || assetClass === assetClasses.Bonds) {
            return (
                <Grid item xs={12}>
                    {getTable(leftTableRows.concat(rightTableRows))}
                </Grid>
            );
        } else {
            return (
                <>
                    <Grid item xs={6}>
                        {getTable(leftTableRows)}
                    </Grid>
                    <Grid item xs={6}>
                        {getTable(rightTableRows)}
                    </Grid>
                </>
            );
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <ETFCard containerStyles={{ position: 'relative' }}>
                <Grid item xs={12}>
                    <ItemHeader>{SectionsNames.ETFPerformance}</ItemHeader>
                    <TableExportMenus
                        exportCallback={() => {
                            exportExcel(excelTable, excelMetadata);
                            const type = 'XLSX';
                            globalThis.analytics?.registerAction?.({
                                action: getDownloadAction(type),
                                cardName: SectionsNames.ETFPerformance,
                                reportType: type,
                                reportName: getExportExcelFileName(excelMetadata),
                            });
                        }}
                    />
                </Grid>
                <Grid container columnSpacing={'36px'}>
                    {getRenderedTable()}
                </Grid>
                <DataAsOfDate date={etfDataFlowsAndPerformance.as_of_date} />
            </ETFCard>
        </ThemeProvider>
    );
}
